import type { RouteRecordRaw } from 'vue-router';

export const Pages: RouteRecordRaw[] = [
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: async () => import('@/views/Privacy/Index.vue'),
    meta: {
      title: 'Privacy Policy',
      layout: 'Blank',
      scopes: [],
    },
  },
];
