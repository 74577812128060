/**
 * This file is used to define custom rules for vee-validate
 * The file name has "old" because these rules are defined during
 * migration from vue 2 to vue 3, for any new rule we prefer using typescript
 */
import moment from 'moment';
import { defineRule } from 'vee-validate';

defineRule('hexcode', (value) => {
  if (!value) return true;
  const isHexcode = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
  return isHexcode.test(value) || 'Please enter a valid hexcode';
});

defineRule('url', (value) => {
  if (!value) return true;
  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return new RegExp(expression).test(value) || 'Please provide a valid URL';
});

defineRule('password', (value, [target]) => {
  return value === target || 'Password confirmation does not match';
});

defineRule('currency', (value) => {
  if (!value) return true;
  const isNumber = /^[+-]?(([1-9][0-9]*)?[0-9](\.[0-9]*)?|\.[0-9]+)$/;
  const isCurrency = /^\d+(?:\.\d{1,2})?$/;
  return (
    (isNumber.test(value) && isCurrency.test(value) && value >= 1) ||
    'Please enter a valid amount'
  );
});

defineRule('phone', (value) => {
  if (!value) return true;
  const expression = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/g;
  return (
    new RegExp(expression).test(value) || 'Please provide a valid phone number'
  );
});

defineRule('time_is_greater_than', (value, [target]) => {
  if (!value || !target) return true;
  const startTime = moment(value, 'h:mma');
  const endTime = moment(target, 'h:mma');
  return (
    (!startTime.isBefore(endTime) && !startTime.isSame(endTime)) ||
    'End time must be later than Start time'
  );
});

defineRule('email-csv', (value) => {
  if (!value) return true;
  const emails = value.split(',');
  let isValid = true;
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  for (let i = 0; i < emails.length; i++) {
    if (emails[i] === '' || !regex.test(emails[i].replace(/\s/g, ''))) {
      isValid = false;
    }
  }
  return isValid || 'Please enter a valid email';
});
