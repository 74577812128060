import axios from 'axios';

const BASE_URL_BUSINESS_V1 =
  'https://mybusinessbusinessinformation.googleapis.com/v1';
const BASE_URL_ACCOUNTS_V1 =
  'https://mybusinessaccountmanagement.googleapis.com/v1';
const BASE_URL_VERIFICATIONS_V1 =
  'https://mybusinessverifications.googleapis.com/v1';

const client = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

const readMask = `?readMask=name,languageCode,storeCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,serviceItems`;

export default {
  /**
   * setToken
   * @param {string} token
   */
  setToken(token) {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  /**
   * getAccountsV1
   * @param {*} token
   * @returns
   */
  getAccountsV1(token) {
    if (!token) return 'Missing token';
    this.setToken(token);

    return client.get('/accounts', {
      baseURL: BASE_URL_ACCOUNTS_V1,
    });
  },

  /**
   * get the admins of a location
   * @param {*} token
   * @param {*} account
   * @returns
   * https://developers.google.com/my-business/reference/accountmanagement/rest/v1/locations.admins/list
   */
  getLocationAdmins(token, location) {
    if (!token) return 'Missing token';
    this.setToken(token);

    return client.get(`/${location}/admins`, {
      baseURL: BASE_URL_ACCOUNTS_V1,
    });
  },

  /**
   * get the admins of a location
   * @param {*} token
   * @param {*} account
   * https://developers.google.com/my-business/reference/verifications/rest/v1/locations.verifications/list
   */
  getLocationVerifications(token, location) {
    if (!token) return 'Missing token';
    this.setToken(token);

    return client.get(`/${location}/verifications`, {
      baseURL: BASE_URL_VERIFICATIONS_V1,
    });
  },

  /**
   * getLocationsV1
   * @param {string} account
   * @param {string} token
   * @param {string} nextPageToken
   * @returns
   */
  getLocationsV1(account, token, nextPageToken) {
    if (!token) return 'Missing token';
    this.setToken(token);

    const hasNextPage = nextPageToken
      ? `&pageToken=${nextPageToken}&pageSize=100`
      : '';

    return client.get(
      `/accounts/${account}/locations${readMask}${hasNextPage}`,
      { baseURL: BASE_URL_BUSINESS_V1 },
    );
  },
};
