import type { AcceptedCurrency } from '@/types/currency';
import type { Locale } from '@/types/i18n';

export const formatMoney = (amount: number, currency: AcceptedCurrency) => {
  const locale = getLocale(currency);
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount);
};

export const getCurrencySymbol = (currency: AcceptedCurrency) => {
  return formatMoney(0, currency).slice(0, 1);
};

export function getLocale(currency: AcceptedCurrency): Locale {
  switch (currency) {
    case 'aud':
      return 'en-au';
    case 'usd':
      return 'en-us';
    case 'eur':
    case 'gbp':
      return 'en-gb';
    case 'cad':
      return 'en-ca';
  }
}
