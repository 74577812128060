import client from '../config';
import * as contact from './contact';
/**
 * Get the user organization
 */
export const getOrganization = async (organizationId: number, isDemo = false) => {
  return client.get(`/users/organization/${organizationId}`, {
    params: {
      demo: isDemo ? '1' : '0',
    },
  });
};

export * from './contact';

export default {
  getOrganization,
  ...contact,
};
