import debounce from 'lodash/debounce';
import moment from 'moment';

import api from '@/services';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    selectedFeedback: null,
    feedback: [],
    total: 0,
    query: null,
    isLoading: false,
  };
};

export const state = getDefaultState();

export const getters = {};

export const mutations = {
  SET_SELECTED_FEEDBACK(state, feedback) {
    state.selectedFeedback = feedback;
  },
  SET_FEEDBACK(state, list) {
    state.feedback = list;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_QUERY(state, query) {
    state.query = query;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  // Used for filtering only to
  // prevent users from ddos'ing
  // when selecting filters
  debounceFetchCustomerReviews: debounce(({ dispatch }, query) => {
    dispatch('fetchAllFeedback', query);
  }, 700),

  async fetchAllFeedback({ commit, state }, query) {
    commit('SET_LOADING', true);

    // Query
    let filter;
    if (query) {
      filter = query;
      commit('SET_QUERY', query);
    } else {
      filter = state.query;
    }

    const params = {
      campaign_code: filter.campaign_code ? filter.campaign_code : '',
      limit: filter.limit ? filter.limit : 25,
      page: filter.page ? filter.page : 1,
      order: filter.sort ? filter.sort : 'date:desc',
      search: filter.search ? filter.search : '',
      rating: filter.rating ? filter.rating : '',
      status: filter.status ? filter.status : '',
      note: filter.note ? filter.note : '',
      posted: filter.posted ? filter.posted : '',
      private: filter.private ? filter.private : '',
      start_date: filter.start_date
        ? filter.start_date
        : moment().subtract('30', 'days').format('YYYY-MM-DD'),
      end_date: filter.end_date
        ? filter.end_date
        : moment().format('YYYY-MM-DD'),
    };

    try {
      const response = await api.rmvfy.getAmplifyAllFeedback(params);
      if (response?.data?.data) {
        commit('SET_FEEDBACK', response.data.data);
        commit('SET_TOTAL', Number.parseInt(response.data.total));
        return response;
      } else if (response.status === 204) {
        // prefer this to return an empty array instead.
        commit('SET_FEEDBACK', []);
        commit('SET_TOTAL', 0);
      } else throw response;
    } catch (error) {
      return error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // data { query, campaign_code }
  async fetchAllFeedbackByView({ dispatch }, data) {
    const query = data.query;
    const campaignCode = data.campaign_code;

    if (campaignCode) {
      query.campaign_code = campaignCode;
    }

    switch (query.view) {
      case 'ComplaintsIntercepted': {
        query.private = '1';
        if (query.posted) delete query.posted;
        break;
      }
      case 'RatingsOnly': {
        query.posted = '0';
        query.private = '0';
        break;
      }
      case 'PublishedReviews': {
        query.posted = '1';
        if (query.private) delete query.private;
        break;
      }
      default: {
        if (query.private) delete query.private;
        if (query.posted) delete query.posted;
      }
    }

    await dispatch('fetchAllFeedback', query);
  },
};
