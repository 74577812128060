export const maskEmail = (email: string | null | undefined) => {
  // If the email is falsy, return an empty string
  if (!email) {
    return '';
  }

  const parts = email.split('@');

  // if no @ symbol, return unknown email
  if (parts.length === 1) {
    return 'Unknown';
  }
  return parts[0].substring(0, 1) + '****' + '@' + parts[1];
};

export const maskAuthToken = (token: string | null | undefined) => {
  if (!token) return 'Unknown Token';
  return token.substring(0, 5) + '****' + token.substring(token.length - 5);
};
