import { v4 as uuidv4 } from 'uuid';

import { defaultToastOptions } from '@/types/toast';
import type { Notification, ToastOptions } from '@/types/toast';

const notifications = ref<Notification[]>([]);
export const useNotificationStore = () => {
  const getOptions = (notifications: ToastOptions) => {
    return {
      ...defaultToastOptions,
      ...notifications,
    };
  };
  const addNotification = (notification: ToastOptions | string) => {
    let options: ToastOptions & typeof defaultToastOptions;
    // assign a unique id to each notification
    const id = uuidv4();

    if (typeof notification === 'string') {
      options = {
        ...defaultToastOptions,
        message: notification,
        id,
      };
    } else {
      options = { ...getOptions(notification), id };
    }
    if (options.clear) {
      removeAllNotifications();
    }
    const now = new Date();
    const timestamp = now.setMilliseconds(
      now.getMilliseconds() + Math.abs(options.timeout),
    );
    options.timestamp = timestamp;

    const timer = setTimeout(() => {
      removeNotification(id);
    }, options.timeout);
    options.timer = timer;
    notifications.value.push(options as Notification);
  };

  const removeNotification = (id: string) => {
    // Clear the timeout then remove the notification
    const index = notifications.value.findIndex(
      notification => notification.id === id,
    );
    if (index !== -1) {
      const notification = notifications.value[index];
      clearTimeout(notification.timer);
      notifications.value.splice(index, 1);
    }
  };

  const removeAllNotifications = () => {
    notifications.value = [];
  };

  const addNotifications = (notifications: ToastOptions[]) => {
    notifications.forEach((notification) => {
      addNotification(notification);
    });
  };

  const notify = (notification: ToastOptions | string) => {
    if (Array.isArray(notification)) {
      addNotifications(notification);
    } else {
      addNotification(notification);
    }
  };

  return {
    addNotification,
    addNotifications,
    removeNotification,
    removeAllNotifications,
    notify,
    notifications,
  };
};
