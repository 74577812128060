import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import { all } from '@vee-validate/rules';

configure({
  generateMessage: localize({
    en: {
      fields: {
        postcode: {
          required: 'Please enter valid ZIP/Postal Code',
        },
      },
      ...en,
    },
  }),
});

Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});
