import { z } from 'zod';

export const userSchema = z.object({
  id: z.number({ coerce: true }),
  name: z.string(),
  email: z.string().email(),
  token: z.string(),
  /**
   * This seems always null at the moment
   */
  activated_at: z.string().nullable(),
  /**
   * Not always present from response
   */
  isDev: z.boolean().optional(),
  /**
   * Not always present from response
   */
  isAdmin: z.boolean().optional(),
});

export type User = z.infer<typeof userSchema>;
