import { userSchema } from '@/types/user';
import type { User } from '@/types/user';
import { zod } from '@/utils';

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null);

  const email = computed(() => user.value?.email ?? '');
  const token = computed(() => user.value?.token ?? '');
  const isInternalUser = computed(() => {
    if (!user.value?.isDev && !user.value?.isAdmin) {
      return false;
    }

    return true;
  });

  const setUser = (newUser: User) => {
    const { data: _user, error } = zod.verifyData(newUser, userSchema);

    if (error) {
      user.value = null;
      return;
    }

    user.value = _user;
  };

  return {
    /**
     * User data
     */
    user,
    /**
     * Set user
     */
    setUser,
    /**
     * User email
     */
    email,
    /**
     * Access Token for API requests
     */
    token,
    /**
     * Is the user an internal user
     */
    isInternalUser,
  };
});
