import moment from 'moment';

import api from '@/services';
import { getSlotUsed } from '@/utils/store';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    reportSummary: null,
    reports: [],
    total: 0,
    query: null,
    isLoading: false,
  };
};

export const state = getDefaultState();

export const mutations = {
  SET_REPORT_SUMMARY(state, summary) {
    state.reportSummary = summary;
  },
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },
  SET_REPORTS_TOTAL(state, total) {
    state.total = total;
  },
  SET_QUERY(state, query) {
    state.query = query;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  fetchReports({ commit, state }, query) {
    commit('SET_LOADING', true);

    // Query
    let filter;
    if (query) {
      filter = query;
      commit('SET_QUERY', query);
    } else {
      filter = state.query;
    }

    const params = {
      limit: filter.limit ? filter.limit : 25,
      page: filter.page ? filter.page : 1,
      order: filter.sort ? filter.sort : 'date:desc',
      search: filter.search ? filter.search : '',
      start_date: filter.start_date
        ? filter.start_date
        : moment().subtract('30', 'days').format('YYYY-MM-DD'),
      end_date: filter.end_date
        ? filter.end_date
        : moment().format('YYYY-MM-DD'),
    };

    return api.rmvfy
      .getReportAmplifyFeedback(params)
      .then((response) => {
        if (response?.data?.data) {
          const reports = response.data.data.feedback.map((report) => {
            return {
              ...report,
              slots_used: getSlotUsed(report),
            };
          });
          commit('SET_REPORTS', reports);
          commit('SET_REPORT_SUMMARY', response.data.data.summary);
          if (state.total !== Number.parseInt(response.data.total)) {
            commit('SET_REPORTS_TOTAL', Number.parseInt(response.data.total));
          }
          commit('SET_LOADING', false);
          return response;
        } else throw response;
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        return error;
      });
  },
};
