import * as Sentry from '@sentry/vue';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import store from '@/store';
import { getAxiosErrorMessage } from '@/utils/axios';

const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_CRM_API_BASE_URL;

/**
 *  Live CRM will use production API key linked with existing RMVFY API (api.rmvfy.com)
 *  For development & staging, we will use a hardcoded token token
 */
const TEMP_TOKEN = import.meta.env.VITE_APP_REMOVIFY_CRM_TMP_TOKEN || null;

const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosRetry(client, { retries: 2 });

client.interceptors.request.use(
  (config) => {
    const token = TEMP_TOKEN || store.getters['auth/getToken'];
    const customerId = store.getters['user/getCustomerID'];

    if (token) config.headers['X-Removify-Token'] = token;
    if (customerId) config.headers['X-Removify-Customer-Id'] = customerId;

    return config;
  },
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const e = getAxiosErrorMessage(error);

    if (!e.ignore) {
      if (e.data) {
        Sentry.addBreadcrumb({
          category: 'api',
          message: 'API Error check the data for more info',
          level: 'error',
          data: e.data,
        });
      }
      // since we are not rejecting the promise, we need to capture the error with Sentry
      Sentry.captureException(e.error);
    }
    return error;
  },
);

/**
 *  Removify CRM v1
 *  @ https://bitbucket.org/removify/crm/src/master/docs/customer-api.md
 */
export default {
  /**
   *  CUSTOMER
   */

  /**
   * @name Customer
   * @description retrieve base customer
   * @method GET
   */
  getCustomer(cache) {
    if (cache) client.defaults.headers['Cache-Control'] = 'no-cache';
    return client.get('/customer');
  },

  /**
   *  CUSTOMER BILLING
   */

  /**
   * @name CustomerPaymentMethod
   * @description add a payment method
   * @param {object} data
   * @method POST
   */
  createCustomerPayment(data) {
    console.warn(
      '[DEPRECATED] This version of createCustomerPayment is deprecated, use crm Version 2 instead.',
    );
    return client.post('/customer/payment_methods', data);
  },

  /**
   * @name CustomerPaymentMethods
   * @description update a payment method
   * @param {object} data
   * @method PATCH
   */
  updateCustomerPayment(data) {
    return client.patch('/customer', data);
  },

  /**
   * @name CustomerPaymentMethods
   * @description remove a payment method
   * @param {string} pid
   * @method DELETE
   */
  deleteCustomerPayment(pid) {
    return client.delete('/customer/payment_methods/' + pid);
  },

  /**
   * @name CustomerPaymentMethodsAUBECSDEBIT
   * @description setup payment intent
   * @method POST
   */
  createClientSecret() {
    return client.post('/customer/payment_methods/au_becs_debit');
  },

  /**
   * @name CustomerServices
   * @description merged with Customer GET
   * @method GET
   * @deprecated merged with `Customer`
   */
  getCustomerService(cache) {
    if (cache) client.defaults.headers['Cache-Control'] = 'no-cache';
    return client.get('/customer/services');
  },

  /**
   * @name CustomerServices
   * @description update a payment method for a billing
   * @method PATCH
   * @param {string} sid
   * @param {object} data
   */
  updateCustomerServicePayment(sid, data) {
    return client.patch('/customer/services/' + sid, data);
  },

  /**
   * @name CustomerInvoices
   * @description retrieve customer invoices
   * @method GET
   */
  getCustomerInvoice(cache) {
    if (cache) client.defaults.headers['Cache-Control'] = 'no-cache';
    return client.get('/customer/invoices');
  },

  /**
   * @name CustomerBilling
   * @description retrieve billing info
   * @method GET
   */
  getCustomerBilling(cache) {
    if (cache) client.defaults.headers['Cache-Control'] = 'no-cache';
    return client.get('/customer/billing');
  },

  /**
   * @name CustomerBalancePayment
   * @description  make a payment
   * @method POST
   * @param {object} data (optional)
   */
  makeBalancePayment(data) {
    return client.post('/customer/balance/', data);
  },

  /**
   *  CUSTOMER REVIEWS
   */

  /**
   * @name CustomerReviews
   * @param {object} params
   */
  getCustomerReviews(params) {
    let filterParams = '?';
    if (params.limit) filterParams += 'per_page=' + params.limit;
    if (params.page) filterParams += '&page=' + params.page;
    if (params.order) filterParams += '&sort=' + params.order;
    if (params.review_id) filterParams += '&review_id=' + params.review_id;
    if (params.search)
      filterParams += '&filter[search]=' + encodeURIComponent(params.search);
    if (params.group) filterParams += '&filter[groups]=' + params.group;

    let filterList = '';
    const filterKeys = Object.keys(params.filter);
    const filterLength = filterKeys.length;
    for (let i = 0; i < filterLength; i++) {
      if (params.filter[filterKeys[i]].length > 0) {
        filterList += '&filter[' + filterKeys[i] + ']=';
        filterList += encodeURIComponent(params.filter[filterKeys[i]].join());
      }
    }
    return client.get('customer/reviews' + filterParams + filterList);
  },

  /**
   * @name CustomerReview
   * @param {string} review_id
   */
  getCustomerReview(review_id) {
    return client.get('customer/reviews?filter[review_id]=' + review_id);
  },

  /**
   * @name CustomerReviews
   * @param {object} data
   * @deprecated
   */
  updateReview(data) {
    console.warn(
      '[DEPRECATED] This version of updateReview is deprecated, use removify Version 2 instead.',
    );
    return client.patch('customer/reviews/' + data.id, {
      keep: data.keep,
    });
  },

  /**
   *  CUSTOMER GROUP PUBLISHERS
   */

  /**
   * @name CustomerPublishers
   * @method GET
   */
  getCustomerPublishers(params) {
    let filterParams = '';
    if (params.group) filterParams += '?filter[groups]=' + params.group;
    return client.get('/customer/publishers' + filterParams);
  },

  /**
   * @name - CustomerPublishers
   * @param {object} data
   * @method PATCH
   * @deprecated use rmvfyV2 instead
   */
  updatePublisher(data) {
    console.warn('[DEPRECATED] `crm.updatePublisher` is deprecated.');
    return client.patch('/customer/publishers', data);
  },

  /**
   * @name - CustomerPublishers
   * @param {string} id
   * @method DELETE
   * @deprecated use rmvfyV2 instead
   */
  deletePublisher(id) {
    console.warn('[DEPRECATED] `crm.deletePublisher` is deprecated');
    return client.delete('/customer/publishers/' + id);
  },

  /**
   * @name - CustomerPublishers
   * @param {object} data
   * @method POST
   * @deprecated use rmvfyV2 instead
   */
  attachPublisherToGroup(data) {
    console.warn('[DEPRECATED] `crm.attachPublisherToGroup` is deprecated');
    return client.post('/customer/publishers/' + data.id + '/groups', [
      data.group,
    ]);
  },

  /**
   * @name - CustomerGroup
   * @param {string} id
   * @method DELETE
   * @deprecated
   */
  deletePublisherGroup(id) {
    console.warn('[DEPRECATED] `crm.deletePublisherGroup` is deprecated.');
    return client.delete('/customer/publishers/groups/' + id);
  },

  /**
   * @name CustomerGroup
   * @method GET
   */
  getCustomerGroups(params) {
    console.warn(
      '[DEPRECATED] `crm.getCustomerGroups` is deprecated use rmvfyv2.getGroups instead',
    );
    let filterParams = '';
    if (params.publisherOnly) filterParams += '?includes=publishers';
    return client.get('/customer/publishers/groups' + filterParams);
  },

  /**
   * @name CustomerGroup
   * @method PATCH
   * @deprecated use rmvfyv2.group.updateGroup instead
   */
  updateCustomerGroup(data) {
    console.warn('[DEPRECATED] `crm.updateCustomerGroup` is deprecated.');
    return client.patch(
      '/customer/publishers/groups?includes=publishers',
      data,
    );
  },

  /**
   * @name CustomerGroup
   * @method POST
   * @deprecated use rmvfyv2.group.createGroup instead
   */
  createCustomerGroup(data) {
    console.warn('[DEPRECATED] `crm.createCustomerGroup` is deprecated');
    return client.post('/customer/publishers/groups', data);
  },

  /**
   * @name AllPublishers
   * @method GET
   */
  getAllPublishers() {
    return client.get('/publishers?filter[reviewshake]=1');
  },

  /**
   *  CUSTOMER CART
   */

  /**
   * @name CustomerCart
   */
  getCustomerCart() {
    return client.get('/customer/cart');
  },

  /**
   * @name CartSubmitRequest
   */
  submitCustomerCart(data) {
    return client.post('/customer/cart', data);
  },

  /**
   * @name CartAddItem
   */
  addItem(item) {
    return client.post('/customer/cart/items/', item);
  },

  /**
   * @name CartUpdateItem
   */
  updateItem(item) {
    return client.patch('/customer/cart/items/' + item.id, {
      is_express: item.isExpress,
      notes: item.notes,
    });
  },

  /**
   * @name CartRemoveItem
   */
  removeItem(id) {
    return client.delete('/customer/cart/items/' + id);
  },

  /**
   * @name CartEmpty
   */
  emptyCart() {
    return client.delete('/customer/cart');
  },

  /**
   * @name CartAddNotes
   */
  addNotes(notes) {
    return client.patch('/customer/cart', { notes });
  },

  /**
   *  USER NOTIFICATION
   * @deprecated
   */
  getUserNotificationSettings() {
    console.warn(
      '[DEPRECATED] `crm.getUserNotificationSettings` is deprecated',
    );
    return client.get('/customer/notification-settings');
  },

  /**
   *
   * @param {*} data
   * @deprecated
   * @returns
   */
  updateUserNotificationSetting(data) {
    console.warn(
      '[DEPRECATED] `crm.updateUserNotificationSetting` is deprecated',
    );
    return client.patch(
      '/customer/notification-settings/' + data.id,
      data.settings,
    );
  },

  /**
   *
   * @param {*} data
   * @deprecated
   * @returns
   */
  updateUserNotificationSettings(data) {
    console.warn(
      '[DEPRECATED] `crm.updateUserNotificationSettings` is deprecated',
    );
    return client.patch('/customer/notification-settings', data);
  },

  createUserNotificationSetting(data) {
    return client.post('/customer/notification-settings', data);
  },

  deleteUserNotificationSetting(id) {
    return client.delete('/customer/notification-settings/' + id);
  },
};
