import type { RouteRecordRaw } from 'vue-router';

export const Messages: RouteRecordRaw[] = [
  {
    path: '/messages',
    name: 'messages',
    component: async () => import('@/views/Messages/Index.vue'),
    meta: {
      title: 'Messages',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['messages.read'],
      menu: 'primary-bottom',
      icon: 'ri ri-messages',
      excludeFooter: true,
    },
  },
];
