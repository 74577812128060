import * as Sentry from '@sentry/vue';
import { useNProgress } from '@vueuse/integrations/useNProgress';
import { createRouter, createWebHistory } from 'vue-router';

import ScopeError from '@/classes/ScopeError';
import { routes } from '@/router/path';
import store from '@/store';
import { useCustomerStore } from '@/stores/useCustomerStore';
import { wait } from '@/utils';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/**
 *  At every route entry, we are going to check for any JWT Authentication Token
 *  within the localStorage.
 */
router.beforeEach(async (to, _from, next) => {
  // Start progress bar
  const { start } = useNProgress();

  start();
  document.title = to.meta.title + ' | Removify';

  /* Virtual Page View */
  window.dataLayer.push({
    event: 'customPageView',
    customPagePath: to.fullPath,
    customTitle: to.meta.title + ' | Removify',
  });

  /**
   *  Let's check if the route requires auth.
   */
  if (to.matched.some(record => record.meta.requiresAuth)) {
    /**
     * The user arrived to a page within the portal/dashboard
     * but does not have a session. Let's save the entry url
     * to redirect them later.
     */
    if (localStorage.getItem('user') === null) {
      if (to.fullPath !== '/') {
        store.commit('user/SET_ENTRY_URL', to.fullPath);
      }
      next({ path: '/login' });
    } else {
      const app = document.getElementById('app')!;

      const user = JSON.parse(localStorage.getItem('user') as string);

      const userLoggedInTrigger = new CustomEvent('userLoggedIn', {
        detail: {
          user: {
            ...user,
            token: null,
          },
        },
      });
      app.dispatchEvent(userLoggedInTrigger);

      try {
        // we check access token at every route entry
        // in case the token itself has been modified
        // or removed.
        const auth = await store.dispatch('auth/getAuthUserFromToken');
        if (auth.authenticated) {
          // this check and actions are only
          // needed if it's not already stored in vuex
          if (!store.getters['user/isSetup']) {
            store.dispatch('user/setupUser', auth.user);
            await store.dispatch('user/fetchUserScopes', auth.user);
            await store.dispatch('user/checkDemoMode');
            await store.dispatch('user/fetchUserSettings');

            // check if organization is in local storage
            // if so, set it in vuex
            const localStorageUserOrg =
              localStorage.getItem('user-organization');
            if (localStorageUserOrg) {
              const organization = JSON.parse(localStorageUserOrg);
              await store.dispatch('user/fetchOrganization', organization);
            }
            // Because our 'admin' users have access to all org,
            // we only need to fetch orgs if the user is not an admin
            if (
              !store.getters['user/isAdmin'] ||
              !store.getters['user/isDev']
            ) {
              await store.dispatch('user/fetchOrganizations');
            }

            const { fetchCustomer } = useCustomerStore();
            fetchCustomer();
          }

          if (store.getters['user/isSetup']) {
            await store.dispatch('user/fetchUserSubscriptions');
          }

          // check scope at every route entry
          if (store.getters['user/checkUserScope'](to.meta.scopes)) {
            // initialiase tracking identifications - hotjar, appcues, sentry
            initTrackID();

            // redirect to entry url if we have one stored
            // @ts-expect-error I don't care vuex type
            const referrerUrl = store.state.user.entryUrl;

            // Admin, Dev, or Multi-account user with no selected organization
            const requireAccountSelector =
              (store.getters['user/isMultiAccount'] ||
                store.getters['user/isDev'] ||
                store.getters['user/isAdmin'] ||
                store.getters['user/isDemoMode']) &&
                store.getters['user/getOID'] === '';

            // 1. Redirect to referrer
            if (
              referrerUrl &&
              !requireAccountSelector &&
              to.fullPath !== referrerUrl
            ) {
              store.commit('user/SET_ENTRY_URL', '');
              // window.location = referrerUrl;
              next(referrerUrl);
            } else if (
              // 2. Organization selection screen (/login/as)
              requireAccountSelector &&
              to.name !== 'subaccount'
            ) {
              const redirectUrl = referrerUrl ? '?redirect=' + referrerUrl : '';
              // window.location = "/login/as" + redirectUrl;
              next('/login/as' + redirectUrl);
            } else {
              // 3. Intended Route
              next();
            }
          } else throw new ScopeError('Out of scope');
        } else {
          // not authenticated to access intended route
          next({ name: '401' });
        }
      } catch (error: any) {
        // disable logging to sentry if not needed
        Sentry.captureException(error);

        // no permissions to access intended route due to out-of-scope
        if (error.name === 'ScopeError') {
          next({ name: '401', query: { p: to.fullPath } });
        } else if (error.name === 'AuthError') {
          // auth related errors
          // Other issues that could be related to Auth
          next({
            name: 'network-error',
            params: {
              code: error.code,
            },
          });
        } else {
          // other errors
          // basically any other errors will fall into this category
          // known ones: google recaptcha, hotjar (if not loaded in time)
          store.dispatch('logout');
          next({
            name: 'login',
            params: {
              message: 'Your session has expired. Please login again.',
            },
          });
        }
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    // Guest pages
    if (
      localStorage.getItem('user') === null ||
      to.name === 'impersonate' ||
      to.name === 'welcome' ||
      to.name === 'email-tracking' ||
      to.name === 'email-tracking-old' ||
      to.name === 'external.gmb-connect' ||
      to.name === 'external.payment-success'
    ) {
      next();
    } else {
      next({ name: 'load' });
    }
  } else {
    next();
  }
});

router.afterEach(async () => {
  // End progress bar after entry
  const { done } = useNProgress();

  // XXX: just give 500 ms of delay,
  // I feel this works better
  await wait(500);
  done();
});

export default router;

let hasInitialisedTID = false;

/**
 * @note This "may" need to be revised for multi-account users when they switch accounts
 */
function initTrackID() {
  const user = store.getters['user/getUserTrackingData'];
  const version = import.meta.env.VERSION;

  // if (user.hasSubscriptions) return;
  if (hasInitialisedTID) return;

  // hotjar - usability monitoring
  window.hj =
    window.hj ||
    function () {
      // Got this function from hotjar, keep it as it is

      // @ts-expect-error hj would be globally set?
      // eslint-disable-next-line prefer-rest-params
      (hj.q = hj.q || []).push(arguments);
    };
  window.hj('identify', user.userId, {
    company: user.organizationName,
    is_internal: user.isAdmin || user.isDev,
    email: user.email,
    version,
  });

  hasInitialisedTID = true;
}
