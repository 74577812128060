import type { RouteRecordRaw } from 'vue-router';
/**
 * These are external routes that typically don't require authentication.
 */
export const External: RouteRecordRaw[] = [
  {
    path: '/ext/gmb-connect/:orgId',
    name: 'external.gmb-connect',
    component: async () => import('@/views/External/ExternalGmbConnection.vue'),
    props: true,
    meta: {
      title: 'Connect to Google My Business',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
  {
    path: '/ext/payment-success',
    name: 'external.payment-success',
    component: () => import('@/views/External/ExternalPaymentThankYou.vue'),
    meta: {
      title: 'We have received your payment',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
];
