import moment from 'moment';
import { z } from 'zod';

import type { MoneyRegexGroups } from '@/constants/regex';
import { moneyRegex } from '@/constants/regex';
import { acceptedCurrency } from '@/types/currency';

/**
 * @group parses
 * Should use preprocess if want to make sure the parse success
 * e.g. check if the value is NaN for parseNumeric
 */
export const parseNumeric = z.string().transform(val => Number(val));
export const parseNumericMoney = z.string().transform(val => Number(val).toFixed(2));
export const parseDatetime = z.string().transform(val => moment(val));
export const parseCurrency = z.string().toLowerCase().pipe(z.enum(acceptedCurrency));

export const preprocessNumeric = () => z.preprocess((val) => {
  const parsed = Number(val);
  if (Number.isNaN(parsed)) throw new Error('Invalid number');
  return parsed;
}, z.number());
export const preprocessNumericMoney = () => z.preprocess((val) => {
  const parsed = Number(val);
  if (Number.isNaN(parsed)) throw new Error('Invalid number');
  return parsed.toFixed(2);
}, z.string());
export const moneyRegexSchema = z.string()
  .regex(moneyRegex, 'Invalid money format')
  .transform((val) => {
    const matches = val.match<MoneyRegexGroups>(moneyRegex);
    if (!matches) {
      return '';
    }

    // Get group first
    if (matches?.groups?.amount) {
      return matches.groups.amount;
    }

    if (matches[2]) {
      return matches[2];
    }

    return '';
  });
