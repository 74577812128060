import type { UnifiedContact } from '@/types/contact';

import client from '../config';

const prefix = '/organization';

/**
 * Get the organization contact
 */
export const getOrganizationContact = async (organizationId: number) => {
  return client.get<{ contacts: UnifiedContact[]; xero_id: string; }>(`${prefix}/${organizationId}/contacts`);
};
