import axios from 'axios';

const API_VERSION = 'v0';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export default {
  /**
   * Auth - login
   * @param {object} data
   */
  login(data) {
    return client.post('/auth/login', data);
  },

  /**
   * Auth - verifyUser
   * Note: used for forgotten password verification (1 hour expiry)
   * @param {string} token
   */
  verifyUser(token) {
    return client.get('/auth/verifyuser/' + token);
  },

  /**
   * Auth - verifyNewUser
   * Note: used for onboarding new Customers (90 day expiry)
   * @param {string} token
   */
  verifyNewUser(token) {
    return client.get('/auth/verifyuser/' + token);
  },

  /**
   * Auth - verifyMe
   * Note: used for impersonation and email-tracking `src/routes/path/Email.js` (1 hour expiry)
   * @param {string} token
   */
  verifyMe(token) {
    return client.get('/auth/verifyme/' + token);
  },

  /**
   * User - updateUserWithTempToken
   * @param {string} token
   * @param {string} userId
   * @param {object} data
   */
  updateUserWithTempToken(token, userId, data) {
    client.defaults.headers['X-Removify-Token'] = token;
    return client.put(`/users/${userId}`, data);
  },

  /**
   * Auth - isActive
   * Note: used for impersonation (1 hour expiry)
   * @param {string} token
   */
  isActive(token) {
    client.defaults.headers['X-Removify-Token'] = token;
    return client.get('/users/active');
  },
};
