// import { wait } from "@/utils';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import split from 'lodash/split';

import api from '@/services';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    comments: [],
    limit: 200,
    total: 0,
    newTotal: 0,
    isLoading: false,
  };
};

export const state = getDefaultState();

export const getters = {
  getComments: (state) => {
    return state.comments;
  },
  getCommentsByDate: (state) => {
    return groupBy(state.comments, comment => split(comment.created, ' ')[0]);
  },
  getTotal: (state) => {
    return state.total;
  },
  getLastComment: (state) => {
    return last(state.comments);
  },
  getLimit: (state) => {
    return state.limit;
  },
};

export const mutations = {
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_NEW_TOTAL(state, total) {
    state.newTotal = Number.parseInt(total);
  },
  INCREMENT_NEW_TOTAL(state) {
    state.newTotal = state.total;
    state.newTotal++;
  },
  SET_LIMIT(state, limit) {
    state.limit = limit;
  },
  ADD_COMMENT(state, comment) {
    state.comments.push(comment);
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  RESET_STATE(state) {
    localStorage.removeItem('c');
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async fetchComments({ commit, state }) {
    commit('SET_LOADING', true);
    // await wait(2000);
    try {
      const params = {
        limit: state.limit,
        cache: state.newTotal === state.total ? 300 : 0,
      };
      const response = await api.rmvfy.getEnquiryComments(params);
      if (response && response.data && response.data.data) {
        /**
         * new total indicates that we have a new comment
         * but not neccessarily updated in zendesk x removify-tools yet.
         * we will check the difference before we commit.
         */
        if (
          state.newTotal === response.data.total ||
          state.total !== response.data.total
        ) {
          commit('SET_COMMENTS', response.data.data.reverse());
          commit('SET_TOTAL', Number.parseInt(response.data.total));
          commit('SET_NEW_TOTAL', Number.parseInt(response.data.total));
          commit('SET_LOADING', false);
        } else if (state.newTotal === 0) {
          commit('SET_COMMENTS', response.data.data.reverse());
          commit('SET_TOTAL', Number.parseInt(response.data.total));
          commit('SET_NEW_TOTAL', Number.parseInt(response.data.total));
          commit('SET_LOADING', false);
        }
        return response;
      } else throw response;
    } catch (error) {
      return error;
    }
  },
  loadMore({ commit, state }) {
    if (state.total > state.limit) {
      commit('SET_LIMIT', state.limit + 10);
    } else {
      return 'No More';
    }
  },
};
