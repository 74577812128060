import type { RouteRecordRaw } from 'vue-router';

export const Auth: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: async () => import('@/views/User/Login/Index.vue'),
    props: true,
    meta: {
      title: 'Login',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
  {
    path: '/login/as',
    name: 'subaccount',
    component: async () => import('@/views/User/SubAccount/Index.vue'),
    props: true,
    meta: {
      title: 'Select An Account',
      layout: 'Blank',
      requiresAuth: true,
      scopes: [],
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: async () => import('@/views/User/Welcome/Index.vue'),
    props: true,
    meta: {
      title: 'Welcome to Removify!',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: async () => import('@/views/User/ForgotPassword/Index.vue'),
    props: true,
    meta: {
      title: 'Forgot Password',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: async () => import('@/views/User/ResetPassword/Index.vue'),
    props: true,
    meta: {
      title: 'Reset Password',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
  {
    path: '/r/:token',
    name: 'impersonate',
    component: async () => import('@/views/User/Impersonate/Index.vue'),
    meta: {
      title: '...',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
  {
    path: '/demo-logout',
    name: 'demo-logout',
    component: async () => import('@/views/User/Logout/Demo.vue'),
    props: true,
    meta: {
      title: 'Logout',
      layout: 'Dashboard',
      scopes: ['demo'],
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: async () => import('@/views/User/Logout/Index.vue'),
    props: true,
    meta: {
      title: 'Logout',
      layout: 'Blank',
      menu: 'top',
      scopes: [],
    },
  },
];
