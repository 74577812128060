import type { CardNetworkBrand } from '@stripe/stripe-js';

import { z } from 'zod';

import { parseCurrency, parseDatetime } from '@/utils/zod';

const brandSchema: z.ZodType<CardNetworkBrand> = z.any();

export const paymentTypeSchema = z.enum(['card', 'au_becs_debit']);
export type PaymentType = z.infer<typeof paymentTypeSchema>;

export const cardSchema = z.object({
  id: z.string(),
  type: paymentTypeSchema.extract(['card']),
  is_default: z.boolean(),
  brand: brandSchema,
  country: z.string().nullable(),
  exp_month: z.number(),
  exp_year: z.number(),
  last4: z.string(),
});

export const auBecsDebitSchema = z.object({
  id: z.string(),
  type: paymentTypeSchema.extract(['au_becs_debit']),
  is_default: z.boolean(),
  bsb_number: z.string(),
  last4: z.string(),
});

export const balanceSchema = z.object({
  balance: z.number(),
  overdue: z.number(),
  updated_at: parseDatetime,
  currency: parseCurrency,
});

export const paymentMethodSchema = z.discriminatedUnion('type', [cardSchema, auBecsDebitSchema]);
export type Card = z.infer<typeof cardSchema>;
export type CardWithExpire = Card & { expired: boolean; expireSoon: boolean; };

export type AuBecsDebit = z.infer<typeof auBecsDebitSchema>;

export type PaymentMethod = z.infer<typeof paymentMethodSchema>;

export type CustomerBalance = z.infer<typeof balanceSchema>;
