import auth from '@/services/api/Auth';
import crm from '@/services/api/CRMService';
import crmV2 from '@/services/api/CRMServiceV2';
import external from '@/services/api/External';
import googlemaps from '@/services/api/GoogleMaps';
import gmb from '@/services/api/GoogleMyBusiness';
import jwt from '@/services/api/JWTService';
import media from '@/services/api/MediaService';
import rmvfy from '@/services/api/RemovifyService';
import rmvfyV2 from '@/services/api/RemovifyServiceV2';

/**
 * @deprecated use `@/api` instead
 */
export default {
  jwt,
  rmvfy,
  rmvfyV2,
  crm,
  auth,
  googlemaps,
  media,
  gmb,
  crmV2,
  external,
};
