import type { RouteRecordRaw } from 'vue-router';

export const Invoice: RouteRecordRaw[] = [
  {
    path: '/billing-invoices/redirect',
    name: 'invoice-redirect',
    component: async () => import('@/pages/invoice/Redirect.vue'),
    meta: {
      title: 'Redirecting to invoice payment...',
      layout: 'Blank',
      scopes: [],
    },
  },
];
