import NProgress from 'nprogress';

import VueBrowserUpdate from './browser-update';
import GlobalComponents from './global-components';
import GlobalDirectives from './global-directives';
import Notifications from './notification';
import SideBar from './sidebar';

import '@/assets/css/amplify/css/amplify.css';
// asset imports
import '@/assets/css/nucleo/css/nucleo.css';
import '@/assets/css/removify/css/removify.css';
import '@/styles/_sass.scss';
import './library/vee-validate-config';

export default {
  /**
   *
   * @param {import('vue').App} app
   */
  install(app) {
    app.use(GlobalComponents);
    app.use(GlobalDirectives);

    app.use(SideBar);
    app.use(Notifications);

    // Browser Detection
    app.use(VueBrowserUpdate, {
      options: {
        required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
        text: `
        <div class="flex justify-center">
          <div id="buorg-message">
            You are using an outdated version of the
            {' '}
            {brow_name}
            {' '}
            and as a result may experience some issues with our platform.
            We recommend updating your browser to the latest version
            <button id="buorgig-wrapper" class="base-button btn-sm btn-secondary"><a id="buorgig" role="button" tabindex="0">Dismiss</a></button>
          </div>
        </div>`,
        container: document.body,
      },
    });

    // Progress Bar
    NProgress.configure({ showSpinner: false });
  },
};
