import axios from 'axios';

import { useOrganizationStore } from '@/stores/useOrganizationStore';
import { useUserStore } from '@/stores/useUserStore';

const API_VERSION = 'v0';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL;

export const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

client.interceptors.request.use(
  (config) => {
    const { token } = storeToRefs(useUserStore());
    const { isDemo, demoName } = storeToRefs(useOrganizationStore());

    if (token.value) config.headers['X-Removify-Token'] = token.value;

    if (isDemo.value && demoName.value) {
      config.headers['X-Demo-Name'] = demoName;
    }
    return config;
  },
);

export function getOrganizationId() {
  const { organizationId } = storeToRefs(useOrganizationStore());

  return organizationId.value;
}

export function getCustomerId() {
  const { customerId } = storeToRefs(useOrganizationStore());
  return customerId.value;
}
