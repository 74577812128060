<template>
  <div class="wrapper">
    <Notifications />
    <DashboardSidebar />
    <div class="main-content">
      <DashboardNavbar></DashboardNavbar>
      <div
        v-if="showAlert"
        class="container-fluid has-[[role='alert']]:mb-2 has-[[role='alert']]:mt-8"
      >
        <GlobalAlert></GlobalAlert>
      </div>
      <router-view
        class="dashboard-content"
        @click="$sidebar.displaySidebar(false)"
      />
      <DashboardFooter />
    </div>
    <GlobalModal />
    <CartSidebar />
  </div>
</template>

<script setup lang="ts">
import DashboardFooter from './Dashboard/Footer.vue';
import GlobalModal from './Dashboard/Modal.vue';
import DashboardNavbar from './Dashboard/Navbar.vue';
import DashboardSidebar from './Dashboard/Sidebar.vue';

const GlobalAlert = defineAsyncComponent(() => import('@/components/Global/GlobalAlert.vue'));
const CartSidebar = defineAsyncComponent(() => import('@/views/Cart/Sidebar.vue'));

const route = useRoute();

const pathShowAlert = ['amplify', 'removals', 'reviews', 'billing', 'invoice'];

const showAlert = computed(() => {
  for (const _p of pathShowAlert) {
    if (route.path.includes(_p)) {
      return true;
    }
  }

  return false;
});
</script>

<style lang="scss">
.dashboard-content {
  min-height: calc(100vh - 184px - 60px); // 184 = footer, 60 = header
}

// Custom height
.messages {
  .dashboard-content {
    min-height: calc(100vh - 60px);
  }
}

.main-content {
  .container-fluid {
    max-width: 2000px;
  }
}
</style>
