import type { RouteRecordRaw } from 'vue-router';

export const Amplify: RouteRecordRaw[] = [
  {
    path: '/amplify',
    redirect: { name: 'amplify-campaigns' },
    component: async () => import('@/views/Amplify/ParentIndex.vue'), // A wrapper component to render child components
    props: true,
    meta: {
      title: 'AMPLIFY',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read'],
      menu: 'primary',
      icon: 'ai ai-amplify',
      badge: {
        text: 'New',
        type: 'new',
        class: 'text-black',
      },
    },
    children: [
      {
        path: '/amplify/campaigns',
        name: 'amplify-campaigns',
        component: async () => import('@/views/Amplify/Campaigns/Index.vue'),
        props: true,
        meta: {
          title: 'Campaigns',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: ['amplify.read', '!amplify_managed.read'],
          menu: 'primary',
        },
      },
      {
        path: '/amplify/performance',
        name: 'amplify-performance',
        component: async () => import('@/views/Amplify/Performance/Index.vue'),
        props: true,
        meta: {
          title: 'Performance',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: [
            'report.read',
            'amplify.read',
            'amplify_pro.read',
            'monitor_reviewshake.read',
          ],
          menu: 'primary',
        },
      },
      {
        path: '/amplify/responses',
        name: 'amplify-responses',
        component: async () => import('@/views/Amplify/Responses/Index.vue'),
        props: true,
        meta: {
          title: 'All Responses',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: ['amplify_pro.read', 'monitor_reviewshake.read'],
          menu: 'primary',
        },
      },
      {
        path: '/amplify/widgets',
        name: 'amplify-widgets',
        component: async () => import('@/views/Amplify/Widgets/Index.vue'),
        props: true,
        meta: {
          title: 'Widget',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: ['amplify_widget.read'],
          menu: 'primary',
          badge: {
            text: 'Beta',
            type: 'info',
          },
        },
      },
      {
        path: '/amplify/upgrade',
        name: 'amplify-upgrade',
        component: async () => import('@/views/Amplify/Campaigns/Index.vue'),
        props: true,
        meta: {
          title: 'Upgrade Plan',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: ['!amplify_pro.read', 'amplify.read'],
          menu: 'primary',
          icon: 'ri ri-upgrade',
        },
      },
    ],
  },
  {
    path: '/amplify/campaigns/new',
    name: 'amplify-new',
    component: async () => import('@/views/Amplify/Campaigns/Create/Index.vue'),
    props: true,
    meta: {
      title: 'General',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read'],
      excludeFooter: true,
    },
  },
  {
    path: '/amplify/campaigns/:campaign_code',
    name: 'amplify-general',
    component: async () => import('@/views/Amplify/Campaigns/Edit/General/Index.vue'),
    props: true,
    meta: {
      title: 'General',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  /**
   * Campaign Type Email
   */
  {
    path: '/amplify/campaigns/:campaign_code/email',
    name: 'amplify-email-content',
    component: async () =>
      import('@/views/Amplify/Campaigns/Edit/Messages/Email/Index.vue'),
    props: true,
    meta: {
      title: 'Email Content & Sending',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  {
    path: '/amplify/campaigns/:campaign_code/contacts',
    name: 'amplify-contacts',
    component: async () =>
      import('@/views/Amplify/Campaigns/Edit/Contacts/Email/Index.vue'),
    props: true,
    meta: {
      title: 'Contacts for Email',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  {
    path: '/amplify/campaigns/:campaign_code/contacts/import',
    name: 'amplify-contacts-import',
    component: async () =>
      import('@/views/Amplify/Campaigns/Edit/Contacts/Email/Index.vue'),
    props: true,
    meta: {
      title: 'Import Contacts for Email',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  /**
   * Campaign Type SMS
   */
  {
    path: '/amplify/campaigns/:campaign_code/sms',
    name: 'amplify-sms-content',
    component: async () =>
      import('@/views/Amplify/Campaigns/Edit/Messages/SMS/Index.vue'),
    props: true,
    meta: {
      title: 'SMS Content & Sending',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  {
    path: '/amplify/campaigns/:campaign_code/contacts-sms',
    name: 'amplify-contacts-sms',
    component: async () =>
      import('@/views/Amplify/Campaigns/Edit/Contacts/SMS/Index.vue'),
    props: true,
    meta: {
      title: 'Contacts for SMS',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  {
    path: '/amplify/campaigns/:campaign_code/contacts-sms/import',
    name: 'amplify-contacts-sms-import',
    component: async () =>
      import('@/views/Amplify/Campaigns/Edit/Contacts/SMS/Index.vue'),
    props: true,
    meta: {
      title: 'Import Contacts for SMS',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  {
    path: '/amplify/campaigns/:campaign_code/landing-page',
    name: 'amplify-landing-page',
    component: async () =>
      import('@/views/Amplify/Campaigns/Edit/LandingPage/Index.vue'),
    props: true,
    meta: {
      title: 'Landing Page',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['amplify.read', '!amplify_managed.read'],
      excludeFooter: true,
    },
  },
  {
    path: '/amplify/performance/:campaign_code/overview',
    name: 'amplify-performance-campaign',
    component: async () =>
      import('@/views/Amplify/Campaigns/Performance/Overview/Index.vue'),
    props: true,
    meta: {
      title: 'Performance Overview',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['report.read', 'amplify.read'],
    },
  },
  {
    path: '/amplify/performance/:campaign_code/responses',
    name: 'amplify-performance-campaign-responses',
    component: async () =>
      import('@/views/Amplify/Campaigns/Performance/Responses/Index.vue'),
    props: true,
    meta: {
      title: 'Responses',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['report.read', 'amplify.read'],
    },
  },
  {
    path: '/amplify/performance/:campaign_code/funnel',
    name: 'amplify-performance-campaign-funnel',
    component: async () =>
      import('@/views/Amplify/Campaigns/Performance/Funnel/Index.vue'),
    props: true,
    meta: {
      title: 'Funnel Performance',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['report.read', 'amplify.read'],
    },
  },
];
