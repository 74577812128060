import axios from 'axios';
import axiosRetry from 'axios-retry';

import store from '@/store';

const API_VERSION = 'v0';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosRetry(client, { retries: 0 });

client.interceptors.request.use(
  (config) => {
    const token = store.getters['auth/getToken'];
    if (token) config.headers['X-Removify-Token'] = token;

    // Demo
    const isDemoMode = store.getters['user/isDemoMode'];
    const demoName = localStorage.getItem('_demoname');

    if (isDemoMode && demoName) {
      config.headers['X-Demo-Name'] = demoName;
    }

    return config;
  },
);

export default client;
