import client from '@/services/api/RemovifyServiceV2/config';

/**
 * Get one monitor by id
 * @param {object} param0
 * @property {number} param0.organization_id
 * @property {number} param0.monitor_id
 * @returns {Promise}
 */
export const getMonitor = ({ organization_id, monitor_id }) => {
  return client.get(`/monitors/${organization_id}/${monitor_id}`);
};

/**
 * Get all monitors
 * @param {number} organization_id
 * @param {object} data
 * @returns {Promise}
 */
export const createMonitor = (organization_id, data) => {
  return client.post(`/monitors/${organization_id}`, data);
};

/**
 * Update a monitor
 * @param {object} param0
 * @property {number} param0.organization_id
 * @property {number} param0.monitor_id
 * @param {object} data
 * @returns {Promise}
 */
export const updateMonitor = ({ organization_id, monitor_id }, data) => {
  return client.put(`/monitors/${organization_id}/${monitor_id}`, data);
};

/**
 * Delete a monitor
 * @param {object} param0
 * @property {number} param0.organization_id
 * @property {number} param0.monitor_id
 * @returns {Promise}
 */
export const deleteMonitor = ({ organization_id, monitor_id }) => {
  return client.delete(`/monitors/${organization_id}/${monitor_id}`);
};

export default {
  getMonitor,
  createMonitor,
  updateMonitor,
  deleteMonitor,
};
