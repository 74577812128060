import type { AxiosError } from 'axios';

import { addBreadcrumb, captureException } from '@sentry/vue';
import axios, { isAxiosError } from 'axios';
import { ZodError } from 'zod';

import type { ApiError } from '@/types/axios';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again later.';

/**
 * Get error message from API
 */
export const getApiErrorMessage = (
  error: string | ApiError | AxiosError,
  defaultMessage = DEFAULT_ERROR_MESSAGE,
  sendToSentry = true,
) => {
  if (typeof error === 'string') {
    return error;
  }

  const isErrorFromAxios = isAxiosError(error);
  if (isErrorFromAxios) {
    if (
      error.message === 'Network Error' ||
      error.name === 'NetworkError' ||
      error.code === axios.AxiosError.ERR_NETWORK) {
      addBreadcrumb({
        category: 'NetworkError',
        message: 'Network Error',
        level: 'error',
        data: error,
      });
    }
    if (error.status === 500) {
      addBreadcrumb({
        category: 'ServerError',
        message: 'Server Error',
        level: 'error',
        data: error,
      });
      return defaultMessage;
    }
  }
  const apiError = isErrorFromAxios ? parseAxiosErrorToApiError(error) : error;

  let message = '';
  if (apiError.errors) {
    const _error = [];
    for (const e of Object.values(apiError.errors)) {
      _error.push(...e);
    }
    message = _error.join(',');
  } else if (apiError.error != null) {
    if (typeof apiError.error === 'string') {
      message = apiError.error;
    } else {
      if (apiError.error.type === 'NOT_ALLOWED') {
        message = defaultMessage;
      } else {
        message = apiError.error.description;
      }
    }
  } else if (apiError.message) {
    message = apiError.message;
  } else {
    message = defaultMessage;
  }

  if (sendToSentry) {
    captureException(new Error(message));
  }

  return message;
};

/**
 * Parse axios error into api error
 */
export const parseAxiosErrorToApiError = (error: AxiosError) => {
  if (!error.response?.data) {
    return {} as ApiError;
  }

  return error.response.data as ApiError;
};

export const addBreadcrumbForError = (error: AxiosError | ZodError | unknown, sendToSentry = false) => {
  if (axios.isAxiosError(error)) {
    addBreadcrumb({
      category: 'AxiosError',
      message: 'Axios Error',
      level: 'info',
      data: error.response,
    });
  } else if (error instanceof ZodError) {
    addBreadcrumb({
      category: 'ZodError',
      message: 'Zod error, check does backend return type match the schema',
      level: 'info',
      data: error,
    });
  } else {
    addBreadcrumb({
      category: 'CommonError',
      message: 'Error that is not AxiosError or ZodError',
      level: 'info',
      data: error as Error,
    });
  }

  if (sendToSentry) {
    console.error(error);
    captureException(error);
  }
};
