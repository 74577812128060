import { jwtDecode } from 'jwt-decode';

export default {
  decodeToken(token) {
    try {
      return jwtDecode(token);
    } catch (err) {
      return err;
    }
  },
  isExpired(token) {
    if (typeof token === 'object' && token.expire) {
      // check our expire format
      const expireDate = timestamp(new Date(token.expire));
      const dateNow = timestamp(new Date());

      if (dateNow >= expireDate) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  },
  checkJSON(str) {
    try {
      JSON.parse(str);
    } catch {
      return false;
    }
    return true;
  },
};

// in seconds
function timestamp(date) {
  return Math.floor(date.getTime() / 1000);
}
