import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';

import api from '@/services';
import { useOrganizationStore } from '@/stores/useOrganizationStore';
import { useUserStore } from '@/stores/useUserStore';

/**
 * List of supported user settings
 * @note These settings are created by the portal to allow for flexibility on frontend
 */
const SUPPORTED_USER_SETTINGS = ['gmb_conversion_alert_dismissed'];

export const namespaced = true;

export const getDefaultState = () => {
  return {
    user: {},
    subscriptions: null,
    requestedSubscriptions: null,

    organizations: [],
    organizationsTotal: 0,
    selectedOrganization: null,
    scopes: [],
    settings: null,

    // Demo only
    demoMode: false,
    selectedDemoOrganization: '',

    entryUrl: '',
    isLoading: false,

    dismissedGmbPopup: false,
  };
};

export const state = getDefaultState();

export const getters = {
  getOID: (state) => {
    return state.selectedOrganization &&
      state.selectedOrganization.organization_id
      ? state.selectedOrganization.organization_id
      : '';
  },
  getPrivacySetting: (state) => {
    return state.selectedOrganization?.privacy
      ? Number.parseInt(state.selectedOrganization.privacy) === 1
      : false;
  },
  getDemoOID: (state) => {
    return state.selectedDemoOrganization ? state.selectedDemoOrganization : '';
  },
  getCustomerID: (state) => {
    return state.selectedOrganization && state.selectedOrganization.customer_id
      ? state.selectedOrganization.customer_id
      : '';
  },
  getSubscriptions: state => (type) => {
    return type && state.subscriptions && state.subscriptions[type]
      ? state.subscriptions[type]
      : null;
  },
  getOrganizationName: (state) => {
    return state.selectedOrganization &&
      state.selectedOrganization.organization_name
      ? state.selectedOrganization.organization_name
      : '';
  },
  getName: (state, getters) => {
    if (getters.isDev || getters.isAdmin) {
      return getters.getUserName;
    }
    return state.selectedOrganization && state.selectedOrganization.client
      ? state.selectedOrganization.client
      : '';
  },
  getUserId: (state) => {
    return state.user && state.user.id;
  },
  getEmail: (state) => {
    return state.user && state.user.email;
  },
  getUserActivationDate: (state) => {
    return state.user && state.user.activated_at;
  },
  getUserName: (state) => {
    return state.user && state.user.name ? state.user.name : '';
  },
  getUserSettings: (state) => {
    return state.settings;
  },
  getUserSetting: state => (field) => {
    return state.settings && state.settings[field]
      ? state.settings[field]
      : null;
  },
  getUserTrackingData: (state, getters) => {
    return {
      userId: state.user?.id,
      email: state.user?.email,
      name: state.user?.name,
      organizationId: state.selectedOrganization?.organization_id,
      organizationName: state.selectedOrganization?.organization_name,
      customerId: state.selectedOrganization?.customer_id,
      isAdmin: getters.isAdmin,
      isDev: getters.isDev,
      activationDate: getters.getUserActivationDate,
      amplifyPlan: getters.getSubscriptions('amplify'),
      watchdogPlan: getters.getSubscriptions('watchdog'),
      hasSubscriptions: !!state.subscriptions,
    };
  },
  getOrganizationByCustomerID: state => (customer_id) => {
    const index = findIndex(state.organizations, { customer_id });
    if (index > -1) return state.organizations[index];
    return null;
  },
  checkUserScope: state => (scopes) => {
    if (state.scopes && state.scopes.length > 0 && scopes) {
      const userScopes = state.scopes.map(({ name }) => name);
      const diff = difference(scopes, userScopes);

      // scope with ! is a negative match
      const exclusion = scopes.filter(scope => scope.startsWith('!'));
      if (exclusion.length > 0) {
        exclusion.forEach((scope, i) => {
          if (userScopes.includes(scope.substring(1))) {
            return false;
          }
          diff.splice(i, 1);
        });
      }

      return diff.length === 0;
    }
    return false;
  },
  checkUserOrganizationId: state => (id) => {
    if (id) {
      const index = findIndex(state.organizations, {
        organization_id: id,
      });
      return index > -1 ? state.organizations[index] : false;
    }
    return false;
  },
  isSetup: (state) => {
    return (
      state.user &&
      state.selectedOrganization?.organization_id &&
      state.scopes &&
      state.scopes.length > 0
    );
  },
  isDemoMode: (state) => {
    return state.demoMode;
  },
  isMultiAccount: (state) => {
    const isMultiAccountScope = findIndex(state.scopes, {
      name: 'auth.multi_account',
    });
    return isMultiAccountScope > -1;
  },
  isRetainerAccount: (state) => {
    const isRetainerAccountScope = findIndex(state.scopes, {
      name: 'auth.retainer_account',
    });
    return isRetainerAccountScope > -1;
  },
  isDev: (state) => {
    const isDeveloperScope = findIndex(state.scopes, { name: 'dev' });
    return isDeveloperScope > -1;
  },
  isAdmin: (state) => {
    const isAdminScope = findIndex(state.scopes, { name: 'admin' });
    return isAdminScope > -1;
  },
  hasOutstandingBalance: (state) => {
    return (
      state.selectedOrganization &&
      state.selectedOrganization.overdue &&
      state.selectedOrganization.overdue > 0
    );
  },
  requireGmbAccess: (state) => {
    return state.selectedOrganization?.flags?.require_gmb_access === true;
  },
  gmbAccess: (state) => {
    return state.selectedOrganization?.gmb_access;
  },
  isUsaGmbPopupDismissed: (state) => {
    return state.dismissedGmbPopup;
  },
  isUsaGbmBannerActive: (state, getters) => {
    return getters.requireGmbAccess && getters.isUsaGmbPopupDismissed;
  },
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ENTRY_URL(state, url) {
    state.entryUrl = url;
  },
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  SET_REQUESTED_SUBSCRIPTIONS(state, requested) {
    state.requestedSubscriptions = requested;
  },
  SET_USER_SETTINGS(state, data) {
    state.settings = data;
  },
  SET_USER_DEMO_MODE(state, bool) {
    const organizationStore = useOrganizationStore();
    organizationStore.isDemo = bool;
    state.demoMode = bool;
  },
  SET_USER_DEMO_ORGANIZATION(state, id) {
    state.selectedDemoOrganization = id;
  },
  SET_USER_SCOPES(state, data) {
    state.scopes = data;
  },
  SET_ORGANIZATIONS(state, data) {
    state.organizations = data;
  },
  SET_ORGANIZATIONS_TOTAL(state, data) {
    state.organizationsTotal = data;
  },
  SET_ORGANIZATION(state, data) {
    state.selectedOrganization = data;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  SET_DISMISSED_GMB_POPUP(state) {
    state.dismissedGmbPopup = true;
  },
  RESET_STATE(state) {
    localStorage.removeItem('user');
    localStorage.removeItem('user-organization');
    localStorage.removeItem('_cid');
    localStorage.removeItem('_internal');
    localStorage.removeItem('_demoname');
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  setupUser({ commit }, data) {
    commit('SET_USER', data);

    const { setUser } = useUserStore();

    setUser(data);

    if (localStorage.getItem('tmp')) {
      localStorage.removeItem('tmp');
    }

    if (localStorage.getItem('tmpUser')) {
      localStorage.removeItem('tmpUser');
    }
  },
  setupOrganization({ commit }, data) {
    commit('SET_ORGANIZATION', data);
    commit('SET_SUBSCRIPTIONS', null); // reset subscription in case it was set
    commit('SET_REQUESTED_SUBSCRIPTIONS', null); // reset subscription in case it was set

    // Also set the organization in pinie
    // This is in the process of migrating to the pinie
    // This may requires extra memory from the browser
    const { setOrganization } = useOrganizationStore();
    setOrganization(data);

    localStorage.setItem(
      'user-organization',
      JSON.stringify({
        ...data,
        timestamp: Date.now(),
      }),
    );
  },
  async fetchUserSettings({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await api.rmvfy.getUserSettings();
      if (response.status === 200) {
        const data = response.data && JSON.parse(response.data);
        Object.keys(data).forEach((key) => {
          if (!SUPPORTED_USER_SETTINGS.includes(key)) {
            delete data[key];
          }
        });
        commit('SET_USER_SETTINGS', data);
        return response;
      } else throw response;
    } catch (error) {
      return error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateUserSettings({ commit, getters }, data) {
    commit('SET_LOADING', true);
    try {
      const settings = getters.getUserSettings;
      Object.keys(data).forEach((key) => {
        if (!SUPPORTED_USER_SETTINGS.includes(key)) {
          delete data[key];
        }
      });
      const response = await api.rmvfy.updateUserSettings({
        setting: {
          ...settings,
          ...data,
        },
      });
      if (response.status === 200) {
        commit('SET_USER_SETTINGS', data);
        return response;
      } else throw response;
    } catch (error) {
      return error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   *
   * @param {*} param0 Vuex methods
   * @param {object} organization a json object representing the organization
   * @returns {object} the response from the API
   * @returns {object} the error from the API
   * @description fetches the organization from the API and sets it in the state
   */
  async fetchOrganization({ dispatch }, organization) {
    // try to fetch the organization from the API
    try {
      const response = await api.rmvfyV2.organization.getOrganization(
        organization.organization_id,
      );
      if (response.status === 200) {
        dispatch('setupOrganization', response.data);

        return response;
      } else throw response;
    } catch (error) {
      return error;
    }
  },

  async fetchOrganizations({ commit, getters, dispatch }) {
    try {
      const response = await api.rmvfy.getOrganizations();
      if (response.status === 200) {
        commit('SET_ORGANIZATIONS', response.data.data);
        commit('SET_ORGANIZATIONS_TOTAL', response.data.total);

        // Single Account User
        if (Number.parseInt(response.data.total) === 1) {
          dispatch('setupOrganization', response.data.data[0]);
        } else if (localStorage.getItem('user-organization')) {
          // already have a stored org? let's check it.
          const localUserOrg =
            localStorage.getItem('user-organization') &&
            JSON.parse(localStorage.getItem('user-organization'));
          const userOrg = getters.checkUserOrganizationId(
            localUserOrg.organization_id,
          );
          if (userOrg) {
            commit('SET_ORGANIZATION', userOrg);
          } else if (getters.isAdmin || getters.isDev) {
            commit('SET_ORGANIZATION', localUserOrg);
          } else if (getters.isDemoMode) {
            commit('SET_ORGANIZATION', localUserOrg);
          } else {
            localStorage.removeItem('user-organization');
          }
        }

        // clear temporary storage
        localStorage.removeItem('_cid');

        return response;
      } else throw response;
    } catch (error) {
      return error;
    }
  },
  async fetchUserScopes({ commit }) {
    try {
      const response = await api.rmvfy.getUserScopes();
      if (response.status === 200) {
        commit('SET_USER_SCOPES', response.data);
        return response;
      } else throw response;
    } catch (error) {
      return error;
    }
  },
  async fetchUserSubscriptions({ commit }, force = false) {
    // if there's not organization selected, we can't fetch subscriptions
    if (!state.selectedOrganization?.organization_id) return;
    // if there's an existing subscription, we don't need to fetch them again
    if (state.subscriptions && !force) return;

    try {
      const response = await api.rmvfy.getUserSubscriptions();
      if (response.status === 200) {
        commit('SET_SUBSCRIPTIONS', response.data.services);
        commit('SET_REQUESTED_SUBSCRIPTIONS', response.data.new_tier_requested);
        return response;
      } else throw response;
    } catch (error) {
      return error;
    }
  },
  async updateDemoMode({ commit, dispatch }, data) {
    commit('SET_LOADING', true);
    try {
      const response = await api.rmvfy.updateDemoMode(data);
      if (response.status === 200) {
        if (response.data.demo) {
          commit('SET_USER_DEMO_MODE', true);
          commit('SET_USER_DEMO_ORGANIZATION', response.data.demo);
        } else {
          commit('SET_USER_DEMO_MODE', false);
          commit('SET_USER_DEMO_ORGANIZATION', '');
        }
        dispatch('fetchUserScopes');
        return response;
      } else throw response;
    } catch (error) {
      return error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async checkDemoMode({ commit, getters, dispatch }) {
    if (!getters.checkUserScope(['demo'])) return;
    commit('SET_LOADING', true);
    try {
      const response = await api.rmvfy.getDemoMode();
      if (response.status === 200) {
        // Check to see if we have a stored
        // organization in the demo field
        if (response.data.demo) {
          // Check to see if a demo name is set
          if (!localStorage.getItem('_demoname')) {
            dispatch('updateDemoMode', {
              demo: response.data.demo,
              demo_reset: true,
            });
          } else {
            commit('SET_USER_DEMO_MODE', true);
            commit('SET_USER_DEMO_ORGANIZATION', response.data.demo);
          }
        } else {
          commit('SET_USER_DEMO_MODE', false);
          commit('SET_USER_DEMO_ORGANIZATION', '');
        }
        dispatch('fetchUserScopes');
        return response;
      } else throw response;
    } catch (error) {
      return error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
