import type { Moment } from 'moment';

import moment from 'moment';

import type { DateFormat } from '@/types/time';

export const formatDateTime = (
  time: string | Moment | Date,
  format: DateFormat,
): string => {
  if (time === '') {
    return '';
  }

  if (time instanceof Date) {
    return moment(time).format(format);
  }

  if (time instanceof moment) {
    return time.format(format);
  }
  return moment(time).format(format);
};

export const formatTime = (
  time: Date | string,
  format: DateFormat = 'D MMM YYYY',
) => {
  return moment(time).format(format);
};

export const timeSince = (time: Date | string) => {
  return moment(time).fromNow();
};

export const getTimeNow = (format: DateFormat = 'D MMM YYYY') => {
  const now = moment().format(format);

  return now;
};

export const getDateFromNow = (
  amount: number,
  unit: moment.unitOfTime.DurationConstructor = 'days',
  format: DateFormat = 'D MMM YYYY',
) => {
  return moment().subtract(amount, unit).format(format);
};

export async function wait(ms: number) {
  return new Promise(r => setTimeout(r, ms));
}
