<template>
  <TooltipProvider :delay-duration="250">
    <div :class="route.name || ''">
      <component :is="layout">
        <router-view :key="route.fullPath" />
      </component>
      <div id="portalModal"></div>
    </div>
  </TooltipProvider>
  <Toaster />
  <VueQueryDevtools />
</template>

<script setup lang="ts">
import { Toaster } from '@removify/ui/toast';
import { TooltipProvider } from '@removify/ui/tooltip';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';

import Blank from '@/layouts/Blank.vue';
import Dashboard from '@/layouts/Dashboard.vue';
import { useNotificationStore } from '@/plugins/notification/useNotificationStore';
import api from '@/services';

defineOptions({
  name: 'RemovifyApp',
});

const route = useRoute();
const { notify } = useNotificationStore();

type Layout = typeof route.meta.layout;

const layouts: Record<Layout, Component> = {
  Dashboard,
  Blank,
};
const layout = computed(() => {
  return layouts[route.meta.layout];
});

const version = import.meta.env.VERSION;
const environment = import.meta.env.VITE_APP_ENV;

useEventListener(window, 'focus', () => {
  checkVersion();
});

onMounted(() => {
  // set DOM meta app version
  const appVersionMeta = document
    .querySelector("[name='app-version']");

  if (appVersionMeta) appVersionMeta.setAttribute('content', version);

  // set to check version every 15 minutes
  setInterval(
    () => {
      checkVersion();
    },
    1000 * 60 * 15,
  );
  // check version
  checkVersion();
});

async function checkVersion() {
  try {
    const response = await api.rmvfyV2.ping();
    const apiVersion = (response && response.data) || '';

    if (environment === 'production' && apiVersion !== version) {
      console.warn(
        'This app version is currently out of date. Please restart.',
      );
      const refreshContent = `
            <div class="text-center">
              <h4 class="text-legacy-blue">Refresh Portal</h4>
              <p>
                An updated version of the portal was released. Please click the below button or refresh the page
              </p>
              <button
                type="button"
                class="el-tooltip btn base-button btn-icon btn-fab btn-primary btn-lg"
                tabindex="0"
                onclick="window.location.reload(true)"
                style="border-radius: 4px;"
              >
                <div class="flex items-center">
                  <i class="ai ai-refresh ai-125x"></i>
                  <span class="btn-inner--text">Refresh</span>
                </div>
              </button>
            </div>`;

      notify({
        message: refreshContent,
        /**
         * Timeout should as same as the interval time
         * @note we might see two notifications but it's fine as long as the user can see the notification
         */
        timeout: 3000000,
        type: 'neutral',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        showClose: false,
        clear: true,
      });
    }
  } catch (error: any) {
    let msg = 'Something went wrong. Please try again later.';
    if (error.response?.data?.errors) {
      msg = '';
      // add errors into msg
      error.response.data.errors.forEach((err: any) => {
        msg += err.message + '.';
      });
    } else if (error.message) {
      msg = error.message + '. Please try again later.';
    }
    notify({
      message: msg,
      timeout: 3000,
      type: 'warning',
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      showClose: false,
    });
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

// global component hide in print
@media print {
  .notifications {
    display: none;
  }
  .sidenav {
    display: none;
  }
  #global-nav-bar {
    display: none;
  }
}
</style>
