import { Chart as ChartJS } from 'chart.js';

export const Charts = {
  mode: 'light', // (themeMode) ? themeMode : 'light';
  fonts: {
    base: "'DM Sans', sans-serif",
  },
  colors: {
    gray: {
      100: '#f1f2f5',
      200: '#e8e9ed',
      300: '#e3e3e3',
      400: '#ced4db',
      500: '#bec7d1',
      600: '#6c757d',
      700: '#525f80',
      800: '#32325d',
      900: '#22252a',
    },
    theme: {
      default: '#172b4d',
      primary: '#5e72e4',
      secondary: '#f4f5f7',
      info: '#11cdef',
      success: '#2dce89',
      danger: '#f5365c',
      warning: '#fb6340',
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
  },
};
const { colors, fonts } = Charts;
// init global options
export const initGlobalOptions = () => {
  /**
   * General configuration
   */
  ChartJS.defaults.responsive = true;
  ChartJS.defaults.maintainAspectRatio = false;
  ChartJS.defaults.color = colors.gray[600];
  ChartJS.defaults.font.family = fonts.base;
  ChartJS.defaults.font.size = 14;

  /**
   * layout
   */
  ChartJS.defaults.layout.padding = 30;
};

const _legendDefaults = () => {
  /**
   * legend
   */
  ChartJS.defaults.plugins.legend.position = 'bottom';
  ChartJS.defaults.plugins.legend.labels.usePointStyle = true;
  ChartJS.defaults.plugins.legend.labels.padding = 20;
  ChartJS.defaults.plugins.legend.labels.boxHeight = 8;
};

const _tooltipDefaults = () => {
  ChartJS.defaults.plugins.tooltip.mode = 'index';
  ChartJS.defaults.plugins.tooltip.intersect = false;
};

const _pieDefaults = () => {
  ChartJS.overrides.pie.plugins.tooltip.mode = 'point';
};
const _doughnutDefaults = () => {
  ChartJS.overrides.doughnut.plugins.tooltip.mode = 'point';
  ChartJS.overrides.doughnut.cutout = 83;
};

export const lineOptions = () => {
  ChartJS.defaults.elements.line.borderWidth = 4;
  ChartJS.defaults.elements.line.borderColor = colors.theme.primary;
  ChartJS.defaults.elements.line.backgroundColor = colors.transparent;
  ChartJS.defaults.elements.line.borderCapStyle = 'round';
};

export const pointOptions = () => {
  ChartJS.defaults.elements.point.radius = 0;
  ChartJS.defaults.elements.point.backgroundColor = colors.theme.primary;
  ChartJS.defaults.elements.point.backgroundColor = colors.theme.primary;
};

export const barOptions = () => {
  ChartJS.defaults.elements.bar.backgroundColor = colors.theme.warning;
};

export const arcOptions = () => {
  ChartJS.defaults.elements.arc.backgroundColor = colors.theme.primary;
  ChartJS.defaults.elements.arc.borderColor = colors.white;
  ChartJS.defaults.elements.arc.borderWidth = 4;
};

export const elementsDefaults = {
  line: lineOptions,
  point: pointOptions,
  bar: barOptions,
  arc: arcOptions,
};

export const doughnutLabelPlugin = (
  lineOffsetX = 30,
  lineOffsetY = 30,
  extraLineLength = 30,
  labelOffset = 10,
  showTitle = true,
  showData = true,
) => {
  /** @type {import('chart.js').Plugin} */
  const doughnutLabelPlugin = {
    id: 'doughnutLineLabel',
    afterDatasetDraw: (chart) => {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;

      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((dataPoint, index) => {
          const { x, y } = dataPoint.tooltipPosition();
          // draw line
          const halfWidth = width / 2;
          const haleHeight = height / 2;

          const xLine = x >= halfWidth ? x + lineOffsetX : x - lineOffsetX;
          const yLine = y >= haleHeight ? y + lineOffsetY : y - lineOffsetY;
          const extraLine = x >= halfWidth ? extraLineLength : -extraLineLength;

          // Line connect to chart
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = dataset.backgroundColor[index];
          ctx.stroke();

          let text = '';
          if (showTitle && showData) {
            text += `${chart.data.labels[index]}: ${chart.data.datasets[i].data[index]}`;
          } else if (showTitle) {
            text = chart.data.labels[index];
          } else if (showData) {
            text += chart.data.datasets[i].data[index];
          }

          const textPosition = x >= halfWidth ? 'left' : 'right';
          const labelOffsetLength = x >= halfWidth ? labelOffset : -labelOffset;
          ctx.textAlign = textPosition;
          ctx.textBaseline = 'middle';
          ctx.fillStyle = dataset.backgroundColor[index];
          ctx.fillText(text, xLine + extraLine + labelOffsetLength, yLine);
        });
      });
    },
  };
  return doughnutLabelPlugin;
};
/**
 *
 * @param {'pie'|'doughnut'|null} [type]
 */
export const chartDefaultPlugins = (type = null) => {
  _legendDefaults();
  _tooltipDefaults();
  if (type === 'pie') {
    _pieDefaults();
  } else if (type === 'doughnut') {
    _doughnutDefaults();
  }
  // _scalesDefaults();
};

export default {
  initGlobalOptions,
  chartDefaultPlugins,
  elementsDefaults,
  lineOptions,
  pointOptions,
  barOptions,
  arcOptions,
};
