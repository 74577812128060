import type { App } from 'vue';

import Notifications from './Notifications.vue';
import { useNotificationStore } from './useNotificationStore';

const { notify } = useNotificationStore();

export const install = (app: App) => {
  app.component('Notifications', Notifications);

  app.config.globalProperties.$notify = notify;
};

export default install;
