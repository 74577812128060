<template>
  <base-nav
    container-classes="container-fluid relative"
    class="navbar-top navbar-expand"
    :class="{ 'navbar-light bg-white': type === 'default' }"
  >
    <div class="hidden xl:block">
      <!-- Heading -->
      <AccountDropdown
        v-if="
          (account.isDev || account.isAdmin || account.isMultiAccount) &&
            !demoMode
        "
      />
      <h1
        v-else-if="demoMode && demoName"
        class="h6 navbar-heading mb-0 hidden items-center font-bold text-black xl:flex"
      >
        {{ demoName }}
        <el-tooltip content="You are currently in demo mode.">
          <badge type="neutral" size="sm ml-2 font-extrabold lh-120 border border-legacy-lighter border-solid">
            Demo
          </badge>
        </el-tooltip>
      </h1>
      <h1
        v-else
        class="h6 navbar-heading mb-0 hidden text-nowrap font-bold text-black xl:block"
      >
        {{
          account.selected && account.selected.organization_name
            ? account.selected.organization_name
            : ''
        }}
      </h1>
    </div>
    <!-- Ham-Bur-Toggler & Company -->
    <!-- Right Menu -->
    <div class="m-0 w-full items-center">
      <ul class="navbar-nav items-center justify-between md:ml-auto xl:justify-end">
        <li class="flex items-center">
          <div
            class="sidenav-toggler flex items-center xl:hidden"
            :class="{
              'active': $sidebar.showSidebar,
              'sidenav-toggler-dark': type === 'default',
              'sidenav-toggler-light': type === 'light',
            }"
            @click="openSidebar"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line bg-legacy-blue"></i>
              <i class="sidenav-toggler-line bg-legacy-blue"></i>
              <i class="sidenav-toggler-line bg-legacy-blue"></i>
            </div>
          </div>
        </li>

        <li class="nav-item center-on-mobile">
          <CartButton
            :authed="hasOrderScope"
            :total="cartTotal"
            :is-active="cartMenuToggle"
            @click="toggleCartMenu"
          />
        </li>
        <li class="nav-item">
          <Avatar />
        </li>
      </ul>
    </div>
  </base-nav>
</template>

<script>
import { tracking } from '@/utils';

export default {
  components: {
    Avatar: defineAsyncComponent(
      () => import('@/views/User/Navbar/Avatar.vue'),
    ),
    CartButton: defineAsyncComponent(() => import('@/views/Cart/Button.vue')),
    AccountDropdown: defineAsyncComponent(
      () => import('@/views/User/SubAccount/Navbar/Dropdown.vue'),
    ),
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description:
        'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
  },
  computed: {
    cartTotal() {
      return this.$store.state.cart.total;
    },
    cartMenuToggle() {
      return this.$store.state.cart.toggleMenu;
    },
    account() {
      return {
        selected: this.$store.state.user.selectedOrganization,
        isDev: this.$store.getters['user/isDev'],
        isAdmin: this.$store.getters['user/isAdmin'],
        isMultiAccount: this.$store.getters['user/isMultiAccount'],
      };
    },
    demoMode() {
      return this.$store.state.user.demoMode;
    },
    demoName() {
      return localStorage.getItem('_demoname');
    },
    hasOrderScope() {
      return this.$store.getters['user/checkUserScope'](['orders.create']);
    },
  },
  methods: {
    openSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleCartMenu() {
      tracking('hj', {
        name: 'tagRecording',
        value: ['Opened New Removal'],
      });
      tracking('gtm', { event: 'newRemovalRequestButton' });

      this.$store.commit('cart/SET_TOGGLE_MENU', !this.cartMenuToggle);
      if (this.cartMenuToggle) document.body.classList.add('cart-open');
      else document.body.classList.remove('cart-open');
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 49;
  box-shadow: 0 0 1px 0 rgba($gray-500, 1);
}

.mobile-app {
  // fix header for mobile
  .navbar-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 49;
    box-shadow: 0 0 1px 0 rgba($gray-500, 1);
  }

  .navbar-heading {
    position: absolute;
    left: 47px;
    z-index: 1;

    &.has-sub-account {
      .title {
        font-size: 0.6875rem;
        display: block;
        margin-top: 5px;
        margin-bottom: -6px;
      }
    }
  }

  .location-separator {
    display: none;
  }

  .center-on-mobile {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 175px;
  }
}

.sidenav-toggler {
  height: 60px;
  line-height: 60px;
  padding-right: 30px;
}
</style>
