import { z } from 'zod';

import { dbBooleanSchema } from './database';

export const organizationSchema = z.object({
  /**
   * Organization ID, unique identifier
   * Will use this to fetch all the data related to this organization
   */
  organization_id: z.number({ coerce: true }),
  /**
   * Organization name
   */
  organization_name: z.string(),
  /**
   * Number of overdue invoices
   */
  overdue: z.number().default(0),
  /**
   * Privacy mode for amplify
   */
  privacy: dbBooleanSchema.default('0'),
  /**
   * Monitor data
   * For now, leave it as any
   */
  monitors: z.any(),
  /**
   * Master tickets
   */
  master_tickets: z.number({ coerce: true }).array().or(z.number({ coerce: true })).optional(),
  /**
   * If GMB access needed
   * TODO: this is a enum which defined in zendesk
   */
  gmb_access: z.string().nullable().optional(),
  /**
   * Flags - seems not used
   */
  flags: z.any(),
  /**
   * CRM customer id
   */
  customer_id: z.number({ coerce: true }),
  /**
   * Country
   */
  country: z.string().nullable().optional(),
  /**
   * Demo organization name
   */
  demo_name: z.string().nullable().optional(),
});

export type Organization = z.infer<typeof organizationSchema>;
