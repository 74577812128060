import { VueQueryPlugin } from '@tanstack/vue-query';

import { initSentry } from '@/plugins/sentry';
import router from '@/router';
import { initGlobalOptions } from '@/utils/chart';
import { getTrackingInitials } from '@/utils/index';

import App from './App.vue';
import globalMixin from './mixins/legacyFilters';
import DashboardPlugin from './plugins/dashboard-plugin';
import { initMixpanel } from './plugins/mixpanel';
import store from './store';

import './styles/index.css';

const pinia = createPinia();
const app = createApp(App);
app.use(DashboardPlugin);
app.use(VueQueryPlugin);
app.mixin(globalMixin);

initGlobalOptions();
initSentry(app, router);
initMixpanel();

document.getElementById('app')!.addEventListener('userLoggedIn', (e) => {
  const _e = e as CustomEvent;
  getTrackingInitials(_e.detail.user);
}, { once: true });

app.use(router);
app.use(store);
app.use(pinia);
app.mount('#app');
