import type { App } from 'vue';
import type { Router } from 'vue-router';

import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/vue';

export function initSentry(app: App<Element>, router: Router) {
  init({
    Vue: app,
    release: 'portal@' + import.meta.env.VERSION,
    environment: import.meta.env.VITE_APP_ENV,
    dsn: import.meta.env.VITE_APP_SENTRY_URL,
    integrations: [
      browserTracingIntegration({ router }),
      replayIntegration(),
    ],
    // Add the benign errors to ignore
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Illegal invocation',
      'Could not load "util".', // Google Maps
      /Unable to preload CSS for (.*)/,
      /Failed to fetch dynamically imported module: (.*)/,
    ],
    denyUrls: [/maps\.googleapis\.com/],
    beforeSend(event, hint) {
      const errorName =
          event.exception &&
          event.exception.values &&
          event.exception.values.length > 0 &&
          event.exception.values[0].value;

      if (errorName === "Unexpected token '<'") {
        console.warn("new version? let's try to refresh ya.");
        window.location.reload();
      }

      if (hint.originalException === 'Timeout') return null;

      return event;
    },

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}
