import type { RouteRecordRaw } from 'vue-router';

export const FAQ: RouteRecordRaw[] = [
  {
    path: '/faq',
    name: 'faq',
    component: async () => import('@/views/FAQ/Index.vue'),
    // fallback if anything went wrong with the new FAQ
    // component: () => import('@/views/FAQ/IndexOld.vue'),
    meta: {
      title: 'FAQ',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['faqs.read'],
      menu: 'primary-bottom',
      icon: 'ri ri-faq',
    },
  },
  {
    path: '/faq/:slug',
    name: 'faq-article',
    component: async () => import('@/views/FAQ/Article/Index.vue'),
    props: true,
    meta: {
      title: 'FAQ',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['faqs.read'],
    },
  },
];
