export default {
  isInitialised() {
    return !!window.google;
  },
  async checkPlaceId(id) {
    try {
      if (window.google.maps) {
        const service = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        );
        const request = { placeId: id };
        const { status } = await new Promise(resolve =>
          service.getDetails(request, status => resolve({ status })),
        );

        return !!(
          status === window.google.maps.places.PlacesServiceStatus.OK ||
          status !== null
        );
      } else throw new Error('Google Maps needs to be initialised.');
    } catch (error) {
      console.error(error);
    }
  },
  async getPlaceDetails(query) {
    try {
      if (window.google) {
        const service = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        );
        const request = {
          query,
          fields: ['name', 'place_id'],
        };

        const { results, status } = await new Promise(resolve =>
          service.findPlaceFromQuery(
            request,
            // pass a callback to getDetails that resolves the promise
            (results, status) => resolve({ results, status }),
          ),
        );

        return status === window.google.maps.places.PlacesServiceStatus.OK
          ? results
          : null;
      } else throw new Error('Google Maps needs to be initialised.');
    } catch (error) {
      console.error(error);
    }
  },
};
