import { chain, filter, indexOf, intersectionBy } from 'lodash';
import kebabCase from 'lodash/kebabCase';
import { marked } from 'marked';

import api from '@/services';
// import filter from "lodash/filter";
// import indexOf from "lodash/indexOf";
// import intersectionBy from "lodash/intersectionBy";
// import chain from "lodash/chain";

export const namespaced = true;

export const getDefaultState = () => {
  return {
    faqs: [],
  };
};

export const state = getDefaultState();

export const getters = {
  getBySlug: state => (slug) => {
    return state.faqs.find(faq => faq.slug === slug);
  },

  getByTag: state => (tag) => {
    return filter(state.faqs, { tags: tag });
  },

  getByTags: state => (tagList) => {
    return filter(
      state.faqs,
      faq => intersectionBy(tagList, faq.tags).length > 0,
    );
  },

  getRemovalStatusByTag: state => (tag) => {
    return state.faqs.filter(
      faq =>
        faq.category === 'Removal Status' && indexOf(faq.tags, tag) !== -1,
    );
  },

  getListByCategory: (state) => {
    return chain(state.faqs)
      .sortBy(({ weight }) => +weight)
      .groupBy(x => x.category)
      .map((value, key) => ({ title: key, data: value }))
      .value();
  },
  getCategories: (state) => {
    return state.faqs
      .map(faq => faq.category)
      .filter((value, index, self) => self.indexOf(value) === index);
  },
};

export const mutations = {
  SET_FAQS(state, faqs) {
    state.faqs = faqs;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async fetchFAQS({ commit, state }) {
    if (state.faqs && state.faqs.length === 0) {
      try {
        const response = await api.rmvfy.getFAQS();
        if (response.data.data) {
          const markedAnswers = response.data.data.map((obj) => {
            obj.slug = kebabCase([obj.category, obj.question]);
            obj.answer = marked(obj.answer);
            obj.snippet = marked(obj.snippet);

            if (obj.tags !== null) {
              obj.tags = obj.tags.split(';');
            }
            return obj;
          });
          commit('SET_FAQS', markedAnswers);
          return response;
        } else throw response;
      } catch (error) {
        return error;
      }
    }
  },
};
