import client from '@/services/api/RemovifyServiceV2/config';

/**
 * @name updateReview
 */
export const updateReview = async (organization_id: number, review_id: string, data: {
  keep: boolean;
}) => {
  return client.put(`/reviews/${organization_id}/review/${review_id}`, data);
};

/**
 * Export reviews to CSV
 */
export const exportCSV = async (organization_id: number) => {
  return client.get(`/reviews/${organization_id}/csv`);
};

export default {
  updateReview,
  exportCSV,
};
