import { z } from 'zod';

import { paymentMethodSchema } from '@/types/payment';
import { parseCurrency } from '@/utils/zod';

export const customerSchema = z.object({
  /**
   * Crm customer id
   */
  id: z.number(),

  /**
   * Customer currency
   */
  currency: parseCurrency,

  /**
   * Alias of currency
   */
  currency_code: parseCurrency,

  /**
   * referral code for sharing
   */
  referrer_code: z.string().nullable(),

  /**
   * referral url for sharing
   */
  referrer_url: z.string().nullable(),

  /**
   * All payment method attached
   */
  payment_methods: paymentMethodSchema.array(),
});

export type Customer = z.infer<typeof customerSchema>;
