import moment from 'moment';

import api from '@/services';
import { getSlotUsed } from '@/utils/store';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    // selected campaign
    campaign: {
      general: null,
      settings: null,
      usage: null,
      page: null,
    },

    // list
    campaigns: [],
    campaignsTotal: 0,

    // total of campaigns relative to the current plan
    numOfCampaignsUsed: 0,

    /**
     * global mapping for fields that are required
     */
    systemFieldsList: {
      'name': 'name',
      'type': 'type',
      'business_name': 'business name',
      'logo': 'logo',
      'business_address': 'business address',
      'timezone': 'timezone',
      'notifications': 'notifications',
      'monitors': 'monitors',
      'schedule[0].subject': 'first subject',
      'schedule[1].subject': 'follow-up subject',
      'sender_name': 'sender name',
      'reply_to': 'reply to',
    },

    query: null,
    isLoading: false,
  };
};

export const state = getDefaultState();

export const getters = {
  getMappingFields: state => (field) => {
    return state.systemFieldsList[field];
  },
  isValidated: (state) => {
    // Type email needs to check campaign settings and general
    if (state.campaign?.general?.type === 'email') {
      return !!(
        state.campaign?.settings?.is_validated &&
        state.campaign?.general?.is_validated
      );
    } else if (state.campaign?.general?.type === 'page') {
      // Type page only needs to check general
      return !!state.campaign?.general?.is_validated;
    } else if (state.campaign?.general?.type === 'sms') {
      // Type page only needs to check general
      return !!(
        state.campaign?.settings?.is_validated &&
        state.campaign?.general?.is_validated
      );
    } else {
      // other types
      return false;
    }
  },
  missingRequirements: (state) => {
    if (state.campaign?.general?.type === 'email') {
      return [
        ...state.campaign?.general?.missing_requirements,
        ...state.campaign?.settings?.missing_requirements,
      ];
    } else if (state.campaign?.general?.type === 'page') {
      return state.campaign?.general?.missing_requirements;
    } else {
      return false;
    }
  },
};

export const mutations = {
  SET_CAMPAIGNS(state, list) {
    state.campaigns = list;
  },
  SET_CAMPAIGNS_TOTAL(state, campaignsTotal) {
    state.campaignsTotal = campaignsTotal;
  },
  SET_SELECTED_CAMPAIGN_GENERAL(state, general) {
    state.campaign.general = general;
  },
  SET_SELECTED_CAMPAIGN_USAGE(state, usage) {
    state.campaign.usage = usage;
  },
  SET_SELECTED_CAMPAIGN_SETTINGS(state, settings) {
    state.campaign.settings = settings;
  },
  SET_SELECTED_CAMPAIGN_PAGE(state, page) {
    state.campaign.page = page;
  },
  SET_NUMBER_OF_CAMPAIGNS_USED(state, total) {
    state.numOfCampaignsUsed = total;
  },
  SET_QUERY(state, query) {
    state.query = query;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async fetchCampaignUsage({ commit }, code) {
    try {
      const campaignUsage = await api.rmvfy.getAmplifyCampaignUsage(code);

      if (campaignUsage.status === 200) {
        commit('SET_SELECTED_CAMPAIGN_USAGE', campaignUsage.data);
      } else throw campaignUsage;
    } catch (error) {
      return error.response;
    }
  },

  async fetchCampaign({ commit }, code) {
    try {
      const campaign = await api.rmvfy.getAmplify(code);
      const campaignUsage = await api.rmvfy.getAmplifyCampaignUsage(code);

      if (campaign.status === 200) {
        commit('SET_SELECTED_CAMPAIGN_GENERAL', campaign.data);
        commit('SET_SELECTED_CAMPAIGN_USAGE', campaignUsage.data);

        let page = JSON.parse(campaign.data.data);

        // some weird bug with the data coming back from the api
        // so we need to parse it again
        if (typeof page === 'string') {
          page = JSON.parse(page);
        }

        commit('SET_SELECTED_CAMPAIGN_PAGE', page);
      } else throw campaign;

      const settings = await api.rmvfy.getAmplifyContentSettings(code);
      if (settings.status === 200) {
        commit('SET_SELECTED_CAMPAIGN_SETTINGS', settings.data.data);
      }
    } catch (error) {
      return error.response;
    }
  },

  async fetchCampaigns({ commit }, { query, getStates }) {
    commit('SET_LOADING', true);

    const { start_date, end_date, ...rest_query } = query;

    const params = {
      limit: rest_query.limit ?? 25,
      page: rest_query.page ?? 1,
      order: rest_query.sort ?? 'created_at:desc',
      search: rest_query.search ?? '',
    };

    if (getStates) {
      params.start_date =
        start_date ?? moment().subtract('30', 'days').format('YYYY-MM-DD');
      params.end_date = end_date ?? moment().format('YYYY-MM-DD');
    }

    try {
      // Due to changes to the campaign overview page to display
      // reporting data, we will leverage off the other endpoint instead
      // const response = await api.rmvfy.getAmplifyCampaigns(params);

      const response = await api.rmvfy.getAmplifyCampaigns(params);
      if (response.status === 200) {
        // remapping to keep the structure consistent with the original
        // endpoint: getAmplifyCampaigns
        const campaigns = response.data.data.map((campaign) => {
          return {
            ...campaign,
            slots_used: getSlotUsed(
              campaign.type,
              campaign.slots_used,
              campaign.current_month_ratings_used,
            ),
            // TODO: rating_total number
          };
        });
        commit('SET_CAMPAIGNS', campaigns);
        commit('SET_CAMPAIGNS_TOTAL', response.data.total);
        commit('SET_NUMBER_OF_CAMPAIGNS_USED', response.data.total_campaigns);

        return response;
      } else throw response;
    } catch (error) {
      return error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAllCampaigns({ commit, state }, query) {
    commit('SET_LOADING', true);

    // Query
    let filter;
    if (query) {
      filter = query;
      commit('SET_QUERY', query);
    } else {
      filter = state.query;
    }

    const params = {
      limit: filter.limit ? filter.limit : 25,
      page: filter.page ? filter.page : 1,
      order: filter.sort ? filter.sort : 'created_at:desc',
      search: filter.search ? filter.search : '',
      start_date: filter.start_date
        ? filter.start_date
        : moment().subtract('30', 'days').format('YYYY-MM-DD'),
      end_date: filter.end_date
        ? filter.end_date
        : moment().format('YYYY-MM-DD'),
      refresh: filter.refresh ? 1 : 0,
    };

    try {
      // Due to changes to the campaign overview page to display
      // reporting data, we will leverage off the other endpoint instead
      // const response = await api.rmvfy.getAmplifyCampaigns(params);

      const response = await api.rmvfy.getReportAmplifyFeedback(params);
      if (response.status === 200) {
        // remapping to keep the structure consistent with the original
        // endpoint: getAmplifyCampaigns
        const campaigns = response.data.data.feedback.map((campaign) => {
          return {
            name: campaign.name,
            campaign_code: campaign.code,
            ...campaign.info,
            is_validated: campaign.info.is_validated,
            stats: campaign.stats,
            funnel: campaign.funnel,
            slots_used: getSlotUsed(
              campaign.info.type,
              campaign.info.slots_used,
              campaign.stats.ratings.rating_total,
            ),
          };
        });
        commit('SET_CAMPAIGNS', campaigns);
        commit('SET_CAMPAIGNS_TOTAL', response.data.total);
        commit('SET_NUMBER_OF_CAMPAIGNS_USED', response.data.total_campaigns);

        return response;
      } else throw response;
    } catch (error) {
      console.log(error);
      return error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
