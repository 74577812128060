import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import moment from 'moment';

import api from '@/services';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    reports: [],
    total: 0,
    reportSummary: null,
    isLoading: false,
    currentPage: 1,
    groups: [],
    group: {
      name: 'All Groups',
      tag_id: null,
    },
    limit: 25,
    sort: 'name:asc',
    searchQuery: '',

    startDate: moment().subtract('180', 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),

    filter: {
      rating_diff: [],
    },

    preset: 'All Monitors',
    presets: [
      {
        name: 'All Monitors',
        filters: [],
      },
      {
        name: 'Rating Improved',
        filters: [
          {
            prop: 'rating_diff',
            val: 'gt_0',
          },
        ],
      },
      {
        name: 'Rating Decreased',
        filters: [
          {
            prop: 'rating_diff',
            val: 'lt_0',
          },
        ],
      },
    ],
  };
};

export const state = getDefaultState();

export const mutations = {
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },
  SET_REPORTS_TOTAL(state, total) {
    state.total = total;
  },
  SET_REPORT_SUMMARY(state, summary) {
    state.reportSummary = summary;
  },
  SET_PAGE(state, page) {
    state.currentPage = page;
  },
  SET_START_DATE(state, date) {
    state.startDate = date;
  },
  SET_END_DATE(state, date) {
    state.endDate = date;
  },
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },
  SET_GROUP(state, group) {
    state.group = group;
  },
  SET_LIMIT(state, page) {
    state.limit = page;
  },
  SET_SORT(state, sort) {
    state.sort = sort;
  },
  SET_SEARCH_QUERY(state, query) {
    state.searchQuery = query;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  SET_FILTER(state, { prop, val }) {
    const index = indexOf(state.filter[prop], val);
    if (index >= 0) {
      state.filter[prop].splice(index, 1);
    } else state.filter[prop].push(val);
  },
  SET_PRESET(state, name) {
    state.preset = name;
  },
  RESET_FILTER(state) {
    state.filter.rating_diff = [];
    state.preset = 'All Reports';
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters = {
  getPresetFilters: (state) => {
    return state.presets;
  },
};

export const actions = {
  fetchGroups({ commit, rootGetters }) {
    const oid = rootGetters['user/getOID'];
    return api.rmvfy
      .getGroups(oid)
      .then((response) => {
        if (response.data) {
          commit('SET_GROUPS', response.data);
        } else {
          commit('SET_GROUPS', []);
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  fetchReports({ commit, state }, data) {
    const params = {
      limit: state.limit,
      page: data?.page ? data.page : 1,
      group: state.group.name === 'All Groups' ? '' : state.group.tag_id,
      order: state.sort,
      filter: state.filter,
      search: state.searchQuery,
      start_date: state.startDate,
      end_date: state.endDate,
    };
    commit('SET_LOADING', true);
    return api.rmvfy
      .getReportLocation(params)
      .then((response) => {
        if (response?.data?.data) {
          commit('SET_REPORTS', response.data.data.monitors);
          commit('SET_REPORT_SUMMARY', response.data.data.summary);
          if (state.total !== Number.parseInt(response.data.total)) {
            commit('SET_REPORTS_TOTAL', Number.parseInt(response.data.total));
          }
          commit('SET_LOADING', false);
          return response;
        } else throw response;
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        return error;
      });
  },
  setPresetFilterBy({ dispatch, commit, state }, preset) {
    commit('SET_SEARCH_QUERY', '');
    commit('RESET_FILTER');
    commit('SET_PRESET', preset);
    const obj = find(state.presets, ['name', preset]);
    for (const key in obj.filters) {
      const x = obj.filters[key];
      const prop = x.prop;
      const val = x.val;
      commit('SET_FILTER', { prop, val });
    }
    dispatch('fetchReports', { page: 1 });
  },
  setSort({ commit, dispatch }, sort) {
    commit('SET_SORT', sort);
    dispatch('fetchReports', { page: 1 });
  },
  setLimit({ commit, dispatch }, limit) {
    commit('SET_LIMIT', limit);
    dispatch('fetchReports', { page: 1 });
  },
  setSearchQuery({ commit, dispatch }, query) {
    commit('SET_SEARCH_QUERY', query);
    dispatch('fetchReports', { page: 1 });
  },
  setGroup({ dispatch, commit }, group) {
    dispatch('resetParams');
    commit('SET_GROUP', group);
    dispatch('fetchReports', { page: 1 });
  },
  clearSearchQuery({ commit }) {
    commit('SET_SEARCH_QUERY', '');
  },
  resetParams({ commit }) {
    commit('SET_SEARCH_QUERY', '');
  },
};
