import type { StripeBranch } from '@/types/stripe';

export const getStripePublishKey = (branch: StripeBranch) => {
  if (branch === 'au') {
    return import.meta.env.VITE_APP_STRIPE_KEY;
  } else if (branch === 'us') {
    return import.meta.env.VITE_APP_STRIPE_KEY_US;
  } else {
    throw new Error('Invalid branch');
  }
};

export const isCreditCardExpired = (expMonth: number, expYear: number) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  return (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth));
};

/**
 * If expire this month, return true
 * I don't want to handle the complex date thing for now
 * if need to check with x months y years, should use moment or @internationalized/date
 */
export const isCreditCardExpireSoon = (expMonth: number, expYear: number) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  return expMonth === currentMonth && expYear === currentYear;
};
