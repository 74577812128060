import contact from './Amplify/contact';
import billing from './billing';
import group from './group';
import monitor from './monitor';
import organization from './organization';
import { ping } from './ping';
import review from './review';
import user from './user';

export default {
  billing,
  ping,
  organization,
  group,
  monitor,
  contact,
  review,
  user,
};
