import type { AxiosError } from 'axios';

import axios from 'axios';

/**
 * @deprecated DO NOT USE THIS FUNCTION DIRECTLY
 *
 * This is only a work around for old axios client that does not reject the error to communicate with sentry
 */
export const getAxiosErrorMessage = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    const { response, code } = error;
    const status = response?.status || 500;

    let ignore = false;
    /**
     * @see https://github.com/axios/axios/issues/1174#issuecomment-349014752
     */
    if (code === axios.AxiosError.ECONNABORTED) {
      ignore = true;
    }

    const message =
      response?.data?.message || 'unknown error or not in correct format';
    const data = response?.data || {
      message: 'unknown error',
    };
    const _error = new Error(
      `Request failed with status code ${status}. ${message}, ${JSON.stringify(
        data,
      )}`,
    );

    return { error: _error, data, ignore };
  }

  return { error, data: null, ignore: false };
};
